import moment from 'moment';

export const booleanToWord = bool => (bool ? `True` : `False`);
export const dateFormat = date => moment(date).format('DD/MM/YYYY');
export const timeFormat = date => moment(date).format('hh:mm a');

export function toDate(dStr, date) {
  var now = new Date(date);
  if (dStr) {
    now.setHours(dStr.substr(0, dStr.indexOf(':')));
    now.setMinutes(dStr.substr(dStr.indexOf(':') + 1));
    now.setSeconds(0);
    return new Date(now).toISOString();
  } else return 'Invalid Format';
}
