import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat } from 'utils/globalFunctions';

const RequestedDeals = () => {
  const [deals, setdeals] = useState([]);
  const _getSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/deal/requested');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setdeals(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _acceptToDeal = async id => {
    try {
      const { data } = await axios.get(`/admin/deal/accept/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _rejectToDeal = async id => {
    try {
      const { data } = await axios.get(`/admin/deal/reject/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getSubCategory();
  }, []);

  return (
    <Page
      title="All Requested Deals"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="Package"
      isLinkButton={true}
      linkButton={() => (
        <Link
          to="/deal/add"
          className="btn btn-outline-primary"
          style={{
            height: 'fit-content',
            margin: 'auto 0px',
          }}
        >
          Add New Deal
        </Link>
      )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All Requested Deals</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Deal Name</th>
                    <th>Deal Id</th>
                    <th>Deal Start Date</th>
                    <th>Deal End Date</th>
                    <th>Deal Is Day</th>
                    <th>Deal Is Month</th>
                    <th>Deal Discount</th>
                    <th>Package Name</th>
                    <th>Package isFemale</th>
                    <th>Package address</th>
                    <th>Package isSeat</th>
                    <th>Vendor Name</th>
                    <th>Vendor Email</th>
                    <th>Vendor Reviews</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Accept</th>
                    <th>Reject</th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((deal, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{deal._id}</td>
                      <td>{deal.dealId.name}</td>
                      <td>{deal.dealId._id}</td>
                      <td>{dateFormat(deal.dealId.startDate)}</td>
                      <td>{dateFormat(deal.dealId.endDate)}</td>
                      <td>{booleanToWord(deal.dealId?.isDay)}</td>
                      <td>{booleanToWord(deal.dealId?.isMonth)}</td>
                      <td>{deal.dealId?.discount}</td>

                      <td>{deal?.packageId?.name}</td>
                      <td>{booleanToWord(deal?.packageId?.isFemale)}</td>
                      <td>{deal?.packageId?.address}</td>
                      <td>{booleanToWord(deal?.packageId?.isSeat)}</td>

                      <td>{deal?.requestedBy?.name}</td>
                      <td>{deal?.requestedBy?.email}</td>
                      <td>{deal?.requestedBy?.reviews?.length}</td>

                      <td>{moment(deal.createdAt).format('DD/MM/YYYY')}</td>
                      <td>{moment(deal.udatedAt).format('DD/MM/YYYY')}</td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => _acceptToDeal(deal._id)}
                        >
                          Accept
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => _rejectToDeal(deal._id)}
                        >
                          Reject
                        </button>
                      </td>
                      {/* <td>
                        <Link
                          to={{
                            pathname: '/deal/add/',
                            deal,
                          }}
                          className="btn btn-primary"
                        >
                          Edit{' '}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: '/deal/detail/',
                            deal,
                          }}
                          params={{ deal }}
                          className="btn btn-primary"
                        >
                          Detail{' '}
                        </Link>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(RequestedDeals);
