import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';

const VoucherDetails = props => {
  const [voucher, setVoucher] = useState(null);
  const [voucherDetail, setVoucherDetail] = useState(null);
  const [userData, setUserData] = useState([]);

  const getAllPackages = async () => {
    if (!voucherDetail) return;
    try {
      const { data } = await axios.get(
        `/admin/get/voucher/${voucherDetail._id}`,
      );
      if (data.error) return toast.error(data.message);
      else {
        setVoucher(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };

    async function _getUserData() {
    try {
      const { data } = await axios.get('/admin/getall/users');
      if (data.error) return toast.error(data.message);
      else {
        setUserData(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (props?.location?.voucher) {
      setVoucherDetail(props?.location?.voucher);
    }
  }, [props]);

  useEffect(() => {
    _getUserData();
    getAllPackages();
  }, [voucherDetail]);

  return (
    <Page
      title="voucher Detail Page"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="voucherDetails"
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <h6>Voucher Name</h6>
                  <p className="text-muted">{voucher?.name}</p>
                </Col>
                <Col>
                  <h6>Voucher Code</h6>
                  <p className="text-muted">{voucher?.voucher}</p>
                </Col>
                <Col>
                  <h6>Voucher Value</h6>
                  <p className="text-muted">{voucher?.value}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Minimum Amount</h6>
                  <p className="text-muted">{voucher?.minimumAmount}</p>
                </Col>
                <Col>
                  <h6>Quantity</h6>
                  <p className="text-muted">{voucher?.quantity}</p>
                </Col>
                <Col>
                  <h6>Quantity Used</h6>
                  <p className="text-muted">{voucher?.quantityUsed}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Type</h6>
                  <p className="text-muted">{voucher?.type}</p>
                </Col>
                <Col>
                  <h6>Is By Percentage</h6>
                  <p className="text-muted">
                    {voucher?.isByPercentage ? 'Yes' : 'No'}
                  </p>
                </Col>
                <Col>
                  <h6>Status</h6>
                  <p className="text-muted">
                    {voucher?.isEnabled ? 'Live' : 'Off'}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Start Date</h6>
                  <p className="text-muted">
                    {moment(voucher?.startDate).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )}
                  </p>
                </Col>
                <Col>
                  <h6>End Date</h6>
                  <p className="text-muted">
                    {moment(voucher?.endDate).format('MMMM Do YYYY, h:mm:ss a')}
                  </p>
                </Col>
                <Col>
                  <h6>Created At</h6>
                  <p className="text-muted">
                    {moment(voucher?.createdAt).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={4} >
                  <h6>Assigned To</h6>
                  {voucher?.assignedTo?.map((userId) => {
                    const user = userData.find((user) => user._id === userId);
                    return <p key={userId} className="text-muted">{user?.email}</p>;
                  })}
                </Col>
                <Col sm={4} >
                  <h6>Redeemed By</h6>
                  {voucher?.redeemedBy?.map((userId) => {
                    const user = userData.find((user) => user._id === userId);
                    return <p key={userId} className="text-muted">{user?.email}</p>;
                  })}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(VoucherDetails);