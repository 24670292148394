import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  Button,
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  { to: '/buttons', name: 'buttons', exact: false, Icon: MdRadioButtonChecked },
  {
    to: '/button-groups',
    name: 'button groups',
    exact: false,
    Icon: MdGroupWork,
  },
  { to: '/forms', name: 'forms', exact: false, Icon: MdChromeReaderMode },
  { to: '/input-groups', name: 'input groups', exact: false, Icon: MdViewList },
  {
    to: '/dropdowns',
    name: 'dropdowns',
    exact: false,
    Icon: MdArrowDropDownCircle,
  },
  { to: '/badges', name: 'badges', exact: false, Icon: MdStar },
  { to: '/alerts', name: 'alerts', exact: false, Icon: MdNotificationsActive },
  { to: '/progress', name: 'progress', exact: false, Icon: MdBrush },
  { to: '/modals', name: 'modals', exact: false, Icon: MdViewDay },
];

const navContents = [
  { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
];

const pageContents = [
  { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  {
    to: '/login-modal',
    name: 'login modal',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navItems = [
  {
    to: '/',
    name: 'dashboard',
    exact: true,
    Icon: MdDashboard,
    isVendor: false,
  },
  {
    to: '/vendors',
    name: 'Vendors',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/voucher',
    name: 'Voucher',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/category',
    name: 'Category',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/subcategory',
    name: 'SubCategory',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  { to: '/users', name: 'Users', exact: false, Icon: MdWeb, isVendor: false },
  {
    to: '/packages',
    name: 'Packages',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/events',
    name: 'Event List',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/deals',
    name: 'Month Deals',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/dealsrequested',
    name: 'Requested Deals',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/dealsday',
    name: 'Day Deals',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/bookings',
    name: 'Bookings',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/constants',
    name: 'Constants',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/milestone',
    name: 'Milestone ',
    exact: false,
    Icon: MdWeb,
    isVendor: false,
  },
  {
    to: '/vendor/packages',
    name: 'My Packages',
    exact: true,
    Icon: MdDashboard,
    isVendor: true,
  },
  {
    to: '/vendor/bookings',
    name: 'My Bookings',
    exact: true,
    Icon: MdDashboard,
    isVendor: true,
  },

  // {
  //   to: '/vendor/reviews',
  //   name: 'My Reviews',
  //   exact: true,
  //   Icon: MdDashboard,
  //   isVendor: true,
  // },

  // { to: '/cards', name: 'cards', exact: false, Icon: MdWeb, isVendor: true  },
  // { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart, isVendor: true  },
  // { to: '/widgets', name: 'widgets', exact: false, Icon: MdWidgets, isVendor: true  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  isVendorActive = JSON.parse(localStorage.getItem('@is_Vendor'));

  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="150"
                height="40"
                className="pr-2 img-fluid"
                alt=""
              />
              {/* <span className="text-white">
                <FaGithub />
              </span> */}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems
              .filter(({ isVendor }) => isVendor === this.isVendorActive)
              .map(({ to, name, exact, Icon, isVendor }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

            <NavItem key={'asdasdasdasd'} className={bem.e('nav-item')}>
              <Button
                id={`navItem-logout`}
                className="text-uppercase"
                activeClassName="active"
                onClick={() => {
                  localStorage.removeItem('@auth_token');
                  localStorage.removeItem('@is_Vendor');
                  window.location.reload();
                }}
              >
                {/* <Icon className={bem.e('nav-item-icon')} /> */}
                <span className="">Log Out</span>
              </Button>
            </NavItem>

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Components</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>

            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Contents')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdSend className={bem.e('nav-item-icon')} />
                  <span className="">Contents</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenContents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}

            {/* <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Pages')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdPages className={bem.e('nav-item-icon')} />
                  <span className="">Pages</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenPages
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem> */}
            {/* <Collapse isOpen={this.state.isOpenPages}>
              {pageContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
