import axios from 'axios';
import {
  STATE_LOGIN,
  STATE_SIGNUP,
  STATE__VENDOR_LOGIN,
} from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { BASE_URL } from './utils/constants';
import 'react-datetime/css/react-datetime.css';

import toast, { Toaster } from 'react-hot-toast';
import Users from 'pages/newPages/Users';
import Packages from 'pages/newPages/Packages';
import PackageAdd from 'pages/newPages/Packages/add';
import PackageDetail from 'pages/newPages/Packages/detail';
import Deal from 'pages/newPages/Deal';
import DealAdd from 'pages/newPages/Deal/add';
import DealDetail from 'pages/newPages/Deal/detail';
import VendorDetails from 'pages/newPages/Vendors/VendorDetails';
import CategoryDetail from 'pages/newPages/CategoryDetail';
import Bookings from 'pages/newPages/Bookings';
import Constants from 'pages/Constants';
import DayDeal from 'pages/newPages/Deal/DayDeal';
import RequestedDeals from 'pages/newPages/Deal/Requested';
import EventList from 'pages/newPages/Packages/EventList';
import VendorPackages from 'pages/newPages/VendorPackages';
import VendorBookings from 'pages/newPages/VendorBookings';
import PackageAddVendor from 'pages/newPages/VendorPackages/PackageAddVendor';
import PackageDetailsVendor from 'pages/newPages/VendorPackages/VendorDetails';
import success from 'pages/fatoraResponse/success';
import error from 'pages/fatoraResponse/error';
const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const VendorPage = React.lazy(() =>
import('pages/newPages/Vendors/VendorPage.js'),
);
const AddVendor = React.lazy(() =>
import('pages/newPages/Vendors/VendorAdd.js'),
);
import VoucherDetails from 'pages/newPages/Voucher/VoucherDetails';
const VoucherPage = React.lazy(() =>
  import('pages/newPages/Voucher/VoucherPage.js'),
);
const AddVoucher = React.lazy(() =>
  import('pages/newPages/Voucher/VoucherAdd.js'),
);
const MilestonePage = React.lazy(() =>
  import('pages/newPages/Milestone/MilestonePage.js'),
);
const AddMilestone = React.lazy(() =>
  import('pages/newPages/Milestone/MilestoneAdd.js'),
);
const Category = React.lazy(() => import('pages/newPages/Category.js'));
const CategoryAdd = React.lazy(() => import('pages/newPages/CategoryAdd.js'));
const SubCategory = React.lazy(() =>
  import('pages/newPages/SubCategory/index.js'),
);
const SubCategoryAdd = React.lazy(() =>
  import('pages/newPages/SubCategory/add.js'),
);
const SubCategoryDetail = React.lazy(() =>
  import('pages/newPages/SubCategory/detail.js'),
);

axios.defaults.baseURL = BASE_URL;

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const token = localStorage.getItem('@auth_token');
console.log({ token });
if (token) {
  axios.defaults.headers.common = { Authorization: `${token}` };
}

console.log({
  TOKEN: axios.defaults.headers.common['Authorization'],
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: localStorage.getItem('@auth_token'),
    };
  }
  // setToken = async () => {

  //   const auth_token = localStorage.getItem('@auth_token');

  // };
  // componentDidMount() {
  //   this.setToken();
  // }
  render() {
    return (
      <BrowserRouter basename="/admin">
        <GAListener>
          <Toaster position="top-right" reverseOrder={false} />{' '}
          <Switch>
            <Route
              exact
              path="/fatora-response-pages/success"
              component={success}
            />
            <Route
              exact
              path="/fatora-response-pages/error"
              component={error}
            />
            {this.state.isAuthenticated ? (
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/" component={DashboardPage} />
                  <Route exact path="/bookings" component={Bookings} />
                  <Route exact path="/users" component={Users} />
                  <Route exact path="/deals" component={Deal} />
                  <Route
                    exact
                    path="/dealsrequested"
                    component={RequestedDeals}
                  />
                  <Route exact path="/dealsday" component={DayDeal} />
                  <Route exact path="/category" component={Category} />
                  <Route exact path="/vendors" component={VendorPage} />
                  <Route exact path="/vendor/add" component={AddVendor} />
                  <Route exact path="/voucher" component={VoucherPage} />
                  <Route exact path="/voucher/add" component={AddVoucher} />
                  <Route exact path="/milestone" component={MilestonePage} />
                  <Route exact path="/milestone/add" component={AddMilestone} />
                  <Route
                    exact
                    path="/vendor/packages"
                    component={VendorPackages}
                  />
                  <Route
                    exact
                    path="/vendor/bookings"
                    component={VendorBookings}
                  />
                  <Route
                    exact
                    path="/vendor/details"
                    component={VendorDetails}
                  />
                  <Route
                    exact
                    path="/voucher/details"
                    component={VoucherDetails}
                  />
                  <Route
                    exact
                    path="/category/detail"
                    component={CategoryDetail}
                  />
                  <Route exact path="/package/add" component={PackageAdd} />
                  <Route
                    exact
                    path="/package/add/vendor"
                    component={PackageAddVendor}
                  />
                  <Route exact path="/constants" component={Constants} />
                  <Route exact path="/category/add" component={CategoryAdd} />
                  <Route exact path="/subcategory" component={SubCategory} />
                  <Route exact path="/packages" component={Packages} />
                  <Route exact path="/events" component={EventList} />
                  <Route
                    exact
                    path="/subcategory/add"
                    component={SubCategoryAdd}
                  />
                  <Route
                    exact
                    path="/subcategory/detail"
                    component={SubCategoryDetail}
                  />
                  <Route exact path="/deal/add" component={DealAdd} />
                  <Route exact path="/deal/detail" component={DealDetail} />
                  <Route
                    exact
                    path="/package/detail"
                    component={PackageDetail}
                  />
                  <Route
                    exact
                    path="/package/detail/vendor"
                    component={PackageDetailsVendor}
                  />
                  <Route exact path="/login-modal" component={AuthModalPage} />
                  <Route exact path="/buttons" component={ButtonPage} />
                  <Route exact path="/cards" component={CardPage} />
                  <Route exact path="/widgets" component={WidgetPage} />
                  <Route exact path="/typography" component={TypographyPage} />
                  <Route exact path="/alerts" component={AlertPage} />
                  <Route exact path="/tables" component={TablePage} />
                  <Route exact path="/badges" component={BadgePage} />
                  <Route
                    exact
                    path="/button-groups"
                    component={ButtonGroupPage}
                  />
                  <Route exact path="/dropdowns" component={DropdownPage} />
                  <Route exact path="/progress" component={ProgressPage} />
                  <Route exact path="/modals" component={ModalPage} />
                  <Route exact path="/forms" component={FormPage} />
                  <Route
                    exact
                    path="/input-groups"
                    component={InputGroupPage}
                  />
                  <Route exact path="/charts" component={ChartPage} />
                </React.Suspense>
              </MainLayout>
            ) : (
              <>
                <Redirect
                  to={{
                    pathname: '/login',
                    // state: { from: location }
                  }}
                />
                <LayoutRoute
                  exact
                  path="/login"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )}
                />
                {/* <LayoutRoute
                  exact
                  path="/vendor-login"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE__VENDOR_LOGIN} />
                  )}
                /> */}
              </>
            )}

            {/* <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            /> */}

            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
