import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, timeFormat } from 'utils/globalFunctions';

const Packages = () => {
  const [categories, setCategories] = useState([]);
  const [blockLoading, setBlockLoading] = useState('');
  const _getSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/packages');
      setBlockLoading('');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setCategories(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _deleteCategory = async id => {
    try {
      const { data } = await axios.delete(`/admin/remove/package/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getSubCategory();
  }, []);

  const setBlockUnBlockPackage = async (id, isBlock) => {
    setBlockLoading(id);
    let data = await axios.post('/admin/packages/block-Unblock', {
      packageId: id,
      isBlock: !isBlock, // becasue we are passing db value into functions. and when we call this function its mean we are setting its value in reverse
    });
    _getSubCategory();
  };

  return (
    <Page
      title="All Packages"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="Package"
      isLinkButton={true}
      linkButton={() => (
        <Link
          to="/package/add"
          className="btn btn-outline-primary"
          style={{
            height: 'fit-content',
            margin: 'auto 0px',
          }}
        >
          Add New Package
        </Link>
      )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All Packages</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Address</th>
                    <th>isFemale</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    {/* <th>Hours</th> */}
                    <th>Price</th>
                    {/* <th>Coin gain on Booking</th>
                    <th>Coin gain on Review</th> */}
                    <th>Start Hour</th>
                    <th>End Hour</th>
                    <th>Is Seat</th>
                    <th>Vendor Name</th>
                    <th>Photos</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>Details</th>
                    <th>Block/Unblock</th>
                  </tr>
                </thead>
                <tbody>
                  {categories
                    .filter(pack => !pack?.isEvent)
                    .map((packageOfEVENT, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{packageOfEVENT._id}</td>
                        <td>{packageOfEVENT.name}</td>
                        <td>
                          {moment(packageOfEVENT.eventStartDate).format(
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td>
                          {moment(
                            packageOfEVENT?.selectedRange[
                              packageOfEVENT?.selectedRange.length - 1
                            ],
                          ).format('DD/MM/YYYY')}
                        </td>
                        <td>{packageOfEVENT?.address}</td>
                        <td>{booleanToWord(packageOfEVENT?.isFemale)}</td>
                        <td>{packageOfEVENT?.location?.coordinates[1]}</td>
                        <td>{packageOfEVENT?.location?.coordinates[0]}</td>
                        {/* <td>{packageOfEVENT?.hours}</td> */}
                        <td>{packageOfEVENT?.price}</td>
                        {/* <td>{packageOfEVENT?.coinsGain}</td>
                      <td>{packageOfEVENT?.reviewCoinsGain}</td> */}
                        <td>{packageOfEVENT?.startHour}</td>
                        <td>{packageOfEVENT?.endHour}</td>
                        <td>{booleanToWord(packageOfEVENT?.isSeat)}</td>
                        <td>{packageOfEVENT?.vendorName}</td>
                        <td>{packageOfEVENT?.photos?.length}</td>

                        <td>
                          {moment(packageOfEVENT.createdAt).format(
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td>
                          {moment(packageOfEVENT.udatedAt).format('DD/MM/YYYY')}
                        </td>

                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => _deleteCategory(packageOfEVENT._id)}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: '/package/add/',
                              packageOfEVENT,
                            }}
                            className="btn btn-primary"
                          >
                            Edit{' '}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: '/package/detail/',
                              packageOfEVENT,
                            }}
                            params={{ packageOfEVENT }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </td>
                        {blockLoading === packageOfEVENT._id ? (
                          <td>
                            <button className="btn btn-primary">
                              Loading...
                            </button>
                          </td>
                        ) : (
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                setBlockUnBlockPackage(
                                  packageOfEVENT._id,
                                  packageOfEVENT.isBlock,
                                )
                              }
                            >
                              {packageOfEVENT.isBlock ? 'Unblock' : 'Block'}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Packages);
