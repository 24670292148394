import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import documentPlaceholder from 'assets/img/documentplaceholdericon.png';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat } from 'utils/globalFunctions';
import Datetime from 'react-datetime';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';

const VendorDetails = props => {
  console.log('props ===> ', props);
  const [vendor, setVendors] = useState(null);
  const [showAddMorePhoto, setShowAddMorePhoto] = useState(false);
  const [newImage, setnewImage] = useState(null);
  const [packages, setPackages] = useState([]);
  const [showAddMoreDocument, setShowAddMoreDocument] = useState(false);
  const [newDocument, setNewDocument] = useState(null);
  const [expiry, setExpiry] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [activeTab, setActiveTab] = useState('preview');
  const [documents, setDocuments] = useState([]);

  const _getAllPackages = async () => {
    try {
      const { data } = await axios.get('/admin/getall/packages');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setPackages(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleAddToPackage = async () => {
    console.log(vendor, 'vendor');
    const { data } = await axios.post(
      `/admin/add/documents/vendors/${vendor?._id}`,
      {
        documentUrl: newDocument,
        expiry: moment(expiry).format('YYYY-MM-DD'),
      },
    );
    console.log(data, 'data from upload');
    toast.success(data.message);
  };

  const openModal = documentUrl => {
    setSelectedDocumentUrl(documentUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    console.log({
      props,
    });
    if (props?.location?.vendor) {
      setVendors(props.location.vendor);
      setDocuments(props.location.vendor.documents);
    }

    _getAllPackages();
  }, [props]);

  const _addToPackage = async () => {
    const { data } = await axios.post(
      `/admin/add/photos/vendors/${vendor?._id}`,
      {
        photoUrl: newImage,
      },
    );

    toast.success(data.message);

    setnewImage('');
  };
  const _addPackageToVendor = async id => {
    const { data } = await axios.post(
      `/admin/vendor/${vendor?._id}/addpackage`,
      {
        packageId: id,
      },
    );
    toast.success(data.message);
  };
  const _removePackageToVendor = async id => {
    const { data } = await axios.post(
      `/admin/vendor/${vendor?._id}/removepackage`,
      {
        packageId: id,
      },
    );
    toast.success(data.message);
  };
  const _removeReviewToVendor = async id => {
    const { data } = await axios.post(
      `/admin/vendor/${vendor?._id}/reviewreview`,
      {
        reviewId: id,
      },
    );
    toast.success(data.message);
  };

  const handleDocumentUpload = async event => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    const { data } = await axios.post(`/upload/assets/image`, formData);
    setNewDocument(data.payload);
  };

  return (
    <Page
      title="Vendor Detail Page"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="VendorDetails"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/vendor/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto 0px',
      //     }}
      //   >
      //     Add VendorDetails
      //   </Link>
      // )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <h6>Id</h6>
                  <p className="text-muted">{vendor?._id}</p>
                </Col>
                <Col>
                  <h6>Name</h6>
                  <p className="text-muted">{vendor?.name}</p>
                </Col>
                <Col>
                  handleAddToPackage
                  <h6>Email</h6>
                  <p className="text-muted">{vendor?.email}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Category Id</h6>
                  <p className="text-muted">{vendor?.categoryId?._id}</p>
                </Col>
                <Col>
                  <h6>Category Name</h6>
                  <p className="text-muted">{vendor?.categoryId?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Description</h6>
                  <p className="text-muted">{vendor?.description}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              Documents
              <Button
                onClick={() => setShowAddMoreDocument(true)}
                style={{ height: 'fit-content' }}
              >
                Add Document
              </Button>
            </CardHeader>
            <CardBody>
              {showAddMoreDocument && (
                <FormGroup row style={{ display: 'flex' }}>
                  <div>
                    <Label>Select Document</Label>
                    <Input type="file" onChange={handleDocumentUpload} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <Label
                        for="Expiry"
                        sm={12}
                        style={{ paddingTop: '0px', paddingBottom: '0px' }}
                      >
                        Set Expiry
                      </Label>
                      <Col sm={12}>
                        <Input
                          type="date"
                          name="Expiry"
                          placeholder="Expiry"
                          value={expiry}
                          onChange={e => setExpiry(e.target.value)}
                        />
                      </Col>
                    </div>
                    <Button
                      onClick={handleAddToPackage}
                      style={{ height: 'fit-content' }}
                    >
                      Upload
                    </Button>
                  </div>
                </FormGroup>
              )}

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {documents.map((document, index) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={documentPlaceholder}
                        width="200"
                        className="img-fluid"
                      />

                      <div className="d-flex">
                        <button
                          onClick={e => {
                            console.log('e => ', e);
                            console.log(
                              'document?.documentUrl ===> ',
                              document?.documentUrl,
                            );
                            openModal(document?.documentUrl);
                          }}
                          class="btn btn-primary m-1"
                        >
                          Preview
                        </button>
                        <button
                          onClick={async e => {
                            console.log('e => ', e);
                            console.log(
                              ' document?.documentUrl, ===> ',
                              document?._id,
                            );

                            const { data } = await axios.post(
                              `/admin/remove/documents/vendors/${vendor?._id}`,
                              {
                                documentId: document?._id,
                              },
                            );
                            console.log(data, 'data from upload');
                            toast.success(data.message);
                          }}
                          class="btn btn-primary m-1"
                        >
                          Delete
                        </button>
                      </div>
                      <Col className="d-flex align-items-center justify-content-center">
                        <h6 className="m-0 pr-1">Expiry </h6>
                        <p className="text-muted m-0">
                          {dateFormat(document?.expiry)}
                        </p>
                      </Col>
                    </div>
                  ))}
                </div>
              </div>
            </CardBody>
            <Modal isOpen={isOpen} toggle={closeModal} size="lg">
              <ModalHeader toggle={closeModal}>Document</ModalHeader>
              <ModalBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink onClick={() => toggleTab('preview')}>
                      Preview
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="preview">
                    {selectedDocumentUrl && (
                      <iframe
                        src={selectedDocumentUrl}
                        title="Document Preview"
                        width="100%"
                        height="500px"
                      />
                    )}
                  </TabPane>
                </TabContent>
              </ModalBody>
            </Modal>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>
              Photos
              <Button onClick={() => setShowAddMorePhoto(true)}>
                Add More
              </Button>
            </CardHeader>

            <CardBody>
              {showAddMorePhoto && (
                <div className="border-1 border-primary">
                  <UploadAndDisplayImage url={newImage} setUrl={setnewImage} />
                  <Button onClick={_addToPackage}>Add To Package</Button>
                </div>
              )}
              <div className="img-grid">
                {vendor?.photos?.map(photo => (
                  <img src={photo?.imageUrl} alt="" className="img-fluid" />
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>Packages</CardHeader>

            <CardBody>
              <div className="img-grid">
                {vendor?.packages?.map(singlePackage => {
                  const fullPackage = packages.find(
                    pac => pac?._id === singlePackage?.packageId,
                  );

                  return (
                    <div className="card card-body">
                      <Row>
                        <Col>
                          <h6>Id</h6>
                          <p className="text-muted">{fullPackage?._id}</p>
                        </Col>
                        <Col>
                          <h6>Name</h6>
                          <p className="text-muted">{fullPackage?.name}</p>
                        </Col>
                        <Col>
                          <h6>Date</h6>
                          <p className="text-muted">
                            {dateFormat(fullPackage?.eventDate)}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>Hours</h6>
                          <p className="text-muted">{fullPackage?.hours}</p>
                        </Col>
                        <Col>
                          <h6>Price</h6>
                          <p className="text-muted">{fullPackage?.price}</p>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname: '/package/detail/',
                              packageOfEVENT: fullPackage,
                            }}
                            params={{ packageOfEVENT: fullPackage }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </Col>
                        <Col>
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              _removePackageToVendor(fullPackage._id)
                            }
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mb-3 mt-3">
            <CardHeader>Add Packages to Vendor</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Address</th>
                    <th>isFemale</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Hours</th>
                    <th>Price</th>
                    <th>Is Seat</th>
                    <th>Photos</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {packages.map((packageOfEVENT, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{packageOfEVENT._id}</td>
                      <td>{packageOfEVENT.name}</td>
                      <td>
                        {moment(packageOfEVENT.eventDate).format('DD/MM/YYYY')}
                      </td>
                      <td>{packageOfEVENT?.address}</td>
                      <td>{packageOfEVENT?.isFemale}</td>
                      <td>{packageOfEVENT?.location?.coordinates[1]}</td>
                      <td>{packageOfEVENT?.location?.coordinates[0]}</td>
                      <td>{packageOfEVENT?.hours}</td>
                      <td>{packageOfEVENT?.price}</td>
                      <td>{packageOfEVENT?.isSeat}</td>
                      <td>{packageOfEVENT?.photos?.length}</td>

                      <td>
                        {moment(packageOfEVENT.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {moment(packageOfEVENT.udatedAt).format('DD/MM/YYYY')}
                      </td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            _addPackageToVendor(packageOfEVENT._id)
                          }
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>Reviews</CardHeader>

            <CardBody>
              <div className="img-grid">
                {vendor?.reviews?.map(review => {
                  {
                    /* const fullPackage = packages.find(
                    pac => pac?._id === singlePackage?.packageId,
                  ); */
                  }

                  return (
                    <div className="card card-body">
                      <Row>
                        <Col>
                          <h6>Id</h6>
                          <p className="text-muted">{review?._id}</p>
                        </Col>
                        <Col>
                          <h6>Title</h6>
                          <p className="text-muted">{review?.reviewTitle}</p>
                        </Col>
                        <Col>
                          <h6>Date</h6>
                          <p className="text-muted">
                            {dateFormat(review?.addedOn)}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>Count</h6>
                          <p className="text-muted">{review?.reviewCount}</p>
                        </Col>
                        <Col>
                          <h6>user name</h6>
                          <p className="text-muted">{review?.userName}</p>
                        </Col>
                        {/* <Col>
                          <Link
                            to={{
                              pathname: '/package/detail/',
                              packageOfEVENT: fullPackage,
                            }}
                            params={{ packageOfEVENT: fullPackage }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </Col> */}
                        <Col>
                          <button
                            className="btn btn-danger"
                            onClick={() => _removeReviewToVendor(review._id)}
                          >
                            Delete Review
                          </button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(VendorDetails);
