import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

const UploadAndDisplayImage = ({ url, setUrl, isVideo = false }) => {
  const _uploadImage = async image => {
    console.log({
      image,
    });
    var formData = new FormData();
    if (isVideo) {
      formData.append('video', image);
    } else {
      formData.append('image', image);
    }
    const { data } = await axios.post(
      isVideo ? '/upload/assets/video' : '/upload/assets/image',
      formData,
    );
    console.log({ data });

    if (data.error) return toast.error(data.message);
    else {
      toast.success(data.message);
      setUrl(data?.payload);
    }
  };

  return (
    <div>
      {url && (
        <div>
          {isVideo ? (
            <video
              muted
              controls
              className="img-thumbnail"
              style={{
                height: 300,
                width: 300,
              }}
              src={url}
            />
          ) : (
            <img
              className="img-thumbnail"
              style={{
                height: 300,
                width: 300,
              }}
              src={url}
            />
          )}
        </div>
      )}

      <input
        type="file"
        // accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
        name="myImage"
        onChange={event => {
          console.log(event.target.files[0]);
          _uploadImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;
