import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const Users = () => {
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coinUser, setCoinUser] = useState(null);
  const [coin, setCoin] = useState(null);
  const [userCoins, setuserCoins] = useState(null);
  const _getCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/users');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setCategories(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  // const _deleteCategory = async id => {
  //   try {
  //     const { data } = await axios.delete(`/admin/delete/category/${id}`);
  //     console.log({ data });
  //     if (data.error) return toast.error(data.message);
  //     else {
  //       toast.success(data.message);
  //       _getCategory();
  //     }
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };
  useEffect(() => {
    _getCategory();
  }, []);
  const _handleSubmit = async e => {
    e.preventDefault();
    const { data } = await axios.post('/admin/addcoin/to/user', {
      phoneNumber: coinUser?.phoneNumber,
      coin,
    });

    console.log({
      data,
    });

    if (data.error) return toast.error(data.message);
    else {
      toast.success(data.message);
      // setUrl(data?.payload);

      setCoin(0);
      setCoinUser(null);
      setIsModalOpen(false);
    }
  };
  const _handleRemove = async e => {
    e.preventDefault();
    const { data } = await axios.post('/admin/addcoin/to/user', {
      phoneNumber: coinUser?.phoneNumber,
      coin: -coin,
    });

    console.log({
      data,
    });

    if (data.error) return toast.error(data.message);
    else {
      toast.success('Coin Removed...');
      // setUrl(data?.payload);

      setCoin(0);
      setCoinUser(null);
      setIsModalOpen(false);
    }
  };
  const _handleDeleteUser = async id => {
    // e.preventDefault();
    const { data } = await axios.delete(`/admin/delete/profile/${id}`);

    console.log({
      data,
    });

    if (data.error) return toast.error(data.message);
    else {
      toast.success(
        'Profile deleted to delete user also, please login to Firebase...',
      );
    }
  };

  return (
    <>
      <Page
        title="All Users Page"
        breadcrumbs={[{ name: 'tables', active: true }]}
        className="Category"
        // isLinkButton={true}
        // linkButton={() => (
        //   <Link
        //     to="/category/add"
        //     className="btn btn-outline-primary"
        //     style={{
        //       height: 'fit-content',
        //       margin: 'auto 0px',
        //     }}
        //   >
        //     Add Category
        //   </Link>
        // )}
      >
        <Row>
          <Col
          // className="offset-md-1"
          >
            <Card className="mb-3">
              <CardHeader>Users</CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Id</th>
                      <th>name</th>
                      <th>Email</th>
                      <th>Coin</th>
                      <th>Address</th>
                      <th>Langauge</th>
                      <th>Gender</th>
                      <th>Currency</th>
                      {/* <th>Latitude</th> */}
                      {/* <th>Longititude</th> */}
                      <th>Age</th>
                      <th>deviceId</th>
                      <th>Device Platform</th>
                      <th>Phone Number</th>
                      <th>Profile Pic</th>
                      <th>Add Coin</th>
                      <th>Remove User</th>
                      {/* <th>Added</th>
                    <th>Updated</th> */}
                      {/* <th>Edit</th> */}
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((category, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{category._id}</td>
                        <td>{category.name}</td>
                        <td>{category.email}</td>
                        <td>{category.coin}</td>
                        <td>{category.address}</td>
                        <td>{category.langauge}</td>
                        <td>{category.gender}</td>
                        <td>{category.currency}</td>
                        {/* <td>{category?.coordinates[1]}</td> */}
                        {/* <td>{category?.coordinates[0]}</td> */}
                        <td>{category.age}</td>
                        <td>{category.deviceId}</td>
                        <td>{category.devicePlatform}</td>
                        <td>{category.phoneNumber}</td>
                        <td>
                          <img
                            src={category?.profilePic}
                            class="img-thumbnail"
                            alt="img-thumbnail"
                          />
                        </td>

                        {/* <td>{moment(category.createdAt).format('DD/MM/YYYY')}</td>
                      <td>{moment(category.udatedAt).format('DD/MM/YYYY')}</td> */}
                        {/* <td>
                        <Link
                          to={{
                            pathname: '/category/add/',
                            category,
                          }}
                          params={{ category }}
                          className="btn btn-primary"
                        >
                          Edit{' '}
                        </Link>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => _deleteCategory(category._id)}
                        >
                          Delete
                        </button>
                      </td> */}
                        <td>
                          <button
                            className="btn btn-success w-100 btn-block"
                            onClick={() => {
                              setIsModalOpen(true);
                              setCoinUser(category);
                              setCoin(0);
                            }}
                            style={{
                              minWidth: 150,
                            }}
                          >
                            Add Coin
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger w-100 btn-block"
                            onClick={() => {
                              _handleDeleteUser(category._id);
                            }}
                            style={{
                              minWidth: 150,
                            }}
                          >
                            Remove User
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        // className={this.props.className}
      >
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          Modal title
        </ModalHeader>
        <ModalBody>
          <div>
            <p>
              {coinUser?.name} has {coinUser?.coin} coin.
            </p>
          </div>
          <Form inline onSubmit={_handleSubmit}>
            <FormGroup>
              <Label for="coin" hidden>
                Number of Coin
              </Label>
              <Input
                type="number"
                name="number"
                id="coin"
                placeholder="Coin"
                value={coin}
                onChange={e => setCoin(e.target.value)}
              />
            </FormGroup>

            <Button color="success" type="submit" className="ml-3">
              Add
            </Button>
            <Button color="danger" onClick={_handleRemove} className="ml-3">
              Remove
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={() => setIsModalOpen(false)}>
            Do Something
          </Button>{' '} */}
          <Button color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default withRouter(Users);
