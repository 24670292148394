import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Table,
  FormGroup,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat } from 'utils/globalFunctions';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';

const VendorDetails = props => {
  const [packageOfEVENT, setCategory] = useState(null);
  const [showAddMorePhoto, setShowAddMorePhoto] = useState(false);
  const [newImage, setnewImage] = useState(null);
  const [slots, setSlots] = useState([]);
  const [slotData, setSlotData] = useState(null);

  const [isShowAddOrUpdateSlot, setIsShowAddOrUpdateSlot] = useState(false);
  const [slotUpdateObject, setSlotUpdateObject] = useState({
    startingTime: null,
    endTime: null,
    price: null,
    total: null,
    available: null,
  });
  const [slotUpdateId, setSlotUpdateId] = useState(null);
  useEffect(() => {
    console.log({
      props,
    });
    if (props?.location?.packageOfEVENT) {
      setCategory(props?.location?.packageOfEVENT);

      _getSlots(props?.location?.packageOfEVENT?._id);
    }
  }, [props]);

  const _addToPackage = async () => {
    const { data } = await axios.post(
      `/admin/add/photos/package/${packageOfEVENT?._id}`,
      {
        photoUrl: newImage,
      },
    );

    toast.success(data.message);

    setnewImage('');
  };
  const _getSlots = async id => {
    const { data } = await axios.get(`/admin/get/slots/package/${id}`);

    console.log({ data });
    setSlots(data?.payload?.times);
    setSlotData(data?.payload);
    // toast.success(data.message);

    // setnewImage('');
  };
  const addSlot = async () => {
    try {
      const { data } = await axios.post(
        `/admin/add/slots/package/${packageOfEVENT?._id}`,
        slotUpdateObject,
      );

      console.log({ data });
      // setSlots(data?.payload?.times);
      // setSlotData(data?.payload);
      toast.success(data.message);

      setSlotUpdateObject({
        startingTime: null,
        endTime: null,
        price: null,
        total: null,
        available: null,
      });
      setSlotUpdateId(null);
      setIsShowAddOrUpdateSlot(false);

      // setnewImage('');
    } catch (error) {}
  };
  const updateSlot = async id => {
    const { data } = await axios.post(
      `/admin/update/slots/package/${packageOfEVENT?._id}/slot/${id}`,
      slotUpdateObject,
    );

    console.log({ data });
    // setSlots(data?.payload?.times);
    // setSlotData(data?.payload);
    toast.success(data.message);

    setSlotUpdateObject({
      startingTime: null,
      endTime: null,
      price: null,
      total: null,
      available: null,
    });
    setSlotUpdateId(null);
    setIsShowAddOrUpdateSlot(false);

    // setnewImage('');
  };
  const deleteSlot = async id => {
    const { data } = await axios.delete(
      `/admin/delete/slots/package/${packageOfEVENT?._id}/slot/${id}`,
    );

    console.log({ data });
    // setSlots(data?.payload?.times);
    // setSlotData(data?.payload);
    toast.success(data.message);

    // setnewImage('');
  };

  return (
    <Page
      title="Package Detail Page"
      breadcrumbs={[{ name: 'tables', active: true }]}
      // className="Vendor Package Details"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/packageOfEVENT/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto 0px',
      //     }}
      //   >
      //     Add VendorDetails
      //   </Link>
      // )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <h6>Id</h6>
                  <p className="text-muted">{packageOfEVENT?._id}</p>
                </Col>
                <Col>
                  <h6>Name</h6>
                  <p className="text-muted">{packageOfEVENT?.name}</p>
                </Col>
                <Col>
                  <h6>Address</h6>
                  <p className="text-muted">{packageOfEVENT?.address}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>isFemale</h6>
                  <p className="text-muted">
                    {booleanToWord(packageOfEVENT?.isFemale)}
                  </p>
                </Col>
                <Col>
                  <h6>Event Date</h6>
                  <p className="text-muted">
                    {dateFormat(packageOfEVENT?.eventDate)}
                  </p>
                </Col>
                <Col>
                  <h6>Latitude</h6>
                  <p className="text-muted">
                    {packageOfEVENT?.location?.coordinates[1]}
                  </p>
                </Col>
                <Col>
                  <h6>Longitude</h6>
                  <p className="text-muted">
                    {packageOfEVENT?.location?.coordinates[0]}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Hours</h6>
                  <p className="text-muted">{packageOfEVENT?.hours}</p>
                </Col>
                <Col>
                  <h6>Price</h6>
                  <p className="text-muted">{packageOfEVENT?.price}</p>
                </Col>
                <Col>
                  <h6>Tax</h6>
                  <p className="text-muted">{packageOfEVENT?.tax}</p>
                </Col>
                <Col>
                  <h6>Total</h6>
                  <p className="text-muted">{packageOfEVENT?.total}</p>
                </Col>
                <Col>
                  <h6>Is Seat</h6>
                  <p className="text-muted">
                    {booleanToWord(packageOfEVENT?.isSeat)}
                  </p>
                </Col>
                <Col>
                  <h6>Is Luxury Picks</h6>
                  <p className="text-muted">
                    {booleanToWord(packageOfEVENT?.isLuxuryPicks)}
                  </p>
                </Col>
                <Col>
                  <h6>Is Top Picks</h6>
                  <p className="text-muted">
                    {booleanToWord(packageOfEVENT?.isLuxuryPicks)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>subCategoryId</h6>
                  <p className="text-muted">{packageOfEVENT?.subCategoryId}</p>
                </Col>
                <Col>
                  <h6>Vendor Id</h6>
                  <p className="text-muted">{packageOfEVENT?.vendorId}</p>
                </Col>
                <Col>
                  <h6>Vendor Name</h6>
                  <p className="text-muted">{packageOfEVENT?.vendorName}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>Decription</h6>
                  <p className="text-muted">{packageOfEVENT?.description}</p>
                </Col>
                <Col>
                  <h6>Latitude</h6>
                  <p className="text-muted">
                    {packageOfEVENT?.location?.coordinates[1]}
                  </p>
                </Col>
                <Col>
                  <h6>Longitude</h6>
                  <p className="text-muted">
                    {packageOfEVENT?.location?.coordinates[0]}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h6>Image</h6>
                  <img
                    src={packageOfEVENT?.photoUrl}
                    class="img-thumbnail"
                    alt="img-thumbnail"
                  />{' '}
                </Col>
                <Col>
                  <h6>Video</h6>
                  <video
                    controls
                    muted
                    src={packageOfEVENT?.videoUrl}
                    class="img-thumbnail"
                    alt="img-thumbnail"
                    style={{}}
                  />{' '}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>
              Photos
              <Button onClick={() => setShowAddMorePhoto(true)}>
                Add More
              </Button>
            </CardHeader>

            <CardBody>
              {showAddMorePhoto && (
                <div className="border-1 border-primary">
                  <UploadAndDisplayImage url={newImage} setUrl={setnewImage} />
                  <Button onClick={_addToPackage}>Add To Package</Button>
                </div>
              )}
              <div className="img-grid">
                {packageOfEVENT?.photos?.map(photo => (
                  <img src={photo} alt="" className="img-fluid" />
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>
              Slots
              <Button
                onClick={() => {
                  setSlotUpdateObject({
                    startingTime: null,
                    endTime: null,
                    price: null,
                    total: null,
                    available: null,
                  });
                  setSlotUpdateId(null);
                  setIsShowAddOrUpdateSlot(true);
                }}
              >
                Add New Slots
              </Button>
            </CardHeader>

            <CardBody>
              {slots?.map(slot => (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="startingTime">startingTime</Label>
                      {/* <Input
                        id="startingTime"
                        // name="email"
                        // placeholder="with a placeholder"
                        
                        type="time"
                        value={}
                      /> */}
                      <p>{slot?.startingTime}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endTime">endTime</Label>
                      {/* <Input
                        id="endTime"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="time"
                        value={slot?.endTime}
                      /> */}
                      <p>{slot?.endTime}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="price">price</Label>
                      {/* <Input
                        id="price"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        value={slot?.price}
                      /> */}

                      <p>{slot?.price}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="total">total</Label>
                      {/* <Input
                        id="total"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        value={slot?.total}
                      /> */}
                      <p>{slot?.total}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="available">available</Label>
                      {/* <Input
                        id="available"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        value={slot?.available}
                      /> */}
                      <p>{slot?.available}</p>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Label
                      for="available"
                      style={{
                        opacity: 0,
                      }}
                    >
                      available
                    </Label>

                    <Button
                      onClick={() => {
                        setSlotUpdateId(slot?._id);
                        setSlotUpdateObject(slot);
                        setIsShowAddOrUpdateSlot(true);
                      }}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col>
                    <Label
                      for="available"
                      style={{
                        opacity: 0,
                      }}
                    >
                      available
                    </Label>
                    <Button
                      onClick={() => {
                        deleteSlot(slot?._id);
                      }}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              ))}
            </CardBody>
          </Card>
          {isShowAddOrUpdateSlot && (
            <Card className="mt-4">
              <CardHeader>Update Slot Detail</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="startingTime">startingTime</Label>
                      <Input
                        id="startingTime"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="time"
                        onChange={e =>
                          setSlotUpdateObject({
                            ...slotUpdateObject,
                            startingTime: e.target.value,
                          })
                        }
                        value={slotUpdateObject?.startingTime}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endTime">endTime</Label>
                      <Input
                        id="endTime"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="time"
                        // value={slot?.}
                        onChange={e =>
                          setSlotUpdateObject({
                            ...slotUpdateObject,
                            endTime: e.target.value,
                          })
                        }
                        value={slotUpdateObject?.endTime}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="price">price</Label>
                      <Input
                        id="price"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        // value={slot?.}
                        onChange={e =>
                          setSlotUpdateObject({
                            ...slotUpdateObject,
                            price: e.target.value,
                          })
                        }
                        value={slotUpdateObject?.price}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="total">total</Label>
                      <Input
                        id="total"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        onChange={e =>
                          setSlotUpdateObject({
                            ...slotUpdateObject,
                            total: e.target.value,
                          })
                        }
                        value={slotUpdateObject?.total}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="available">available</Label>
                      <Input
                        id="available"
                        // name="email"
                        // placeholder="with a placeholder"
                        type="text"
                        // value={slot?.available}
                        onChange={e =>
                          setSlotUpdateObject({
                            ...slotUpdateObject,
                            available: e.target.value,
                          })
                        }
                        value={slotUpdateObject?.available}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Label
                      for="available"
                      style={{
                        opacity: 0,
                      }}
                    >
                      available
                    </Label>

                    <Button
                      onClick={() => {
                        if (slotUpdateId) {
                          // update
                          updateSlot(slotUpdateId);
                        } else {
                          addSlot();
                        }
                      }}
                    >
                      {slotUpdateId ? 'Update' : 'Save'}
                    </Button>
                  </Col>
                  {/* <Col>
                    <Label
                      for="available"
                      style={{
                        opacity: 0,
                      }}
                    >
                      available
                    </Label>
                    <Button>Delete</Button>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(VendorDetails);
