import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat, timeFormat } from 'utils/globalFunctions';

const VendorPackages = () => {
  const [categories, setCategories] = useState([]);
  const [blockLoading, setBlockLoading] = useState('');
  const _getSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/packages/vendor');
      setBlockLoading('');
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setCategories(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _deleteCategory = async id => {
    try {
      const { data } = await axios.get(`/admin/remove/package/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const [packageId, setPackageId] = useState(null);
  const [dealId, setDealId] = useState(null);
  const _requestToAdd = async () => {
    try {
      const { data } = await axios.post('/admin/add/package/deal/request', {
        packageId,
        dealId,
      });
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getSubCategory();
    _getDeals();
  }, []);

  const [deals, setdeals] = useState([]);
  const _getDeals = async () => {
    try {
      const { data } = await axios.get('/admin/getall/deal');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setdeals(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const setBlockUnBlockPackage = async (id, isBlock) => {
    setBlockLoading(id);
    await axios.post('/admin/packages/vendor/block-Unblock', {
      packageId: id,
      isBlock: !isBlock, // becasue we are passing db value into functions. and when we call this function its mean we are setting its value in reverse
    });
    _getSubCategory();
  };

  return (
    <Page
      title="All VendorPackages"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="Package"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/package/add/vendor"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto 0px',
      //     }}
      //   >
      //     Add New Package
      //   </Link>
      // )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All VendorPackages</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Address</th>
                    <th>isFemale</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    {/* <th>Hours</th> */}
                    <th>Price</th>
                    {/* <th>Coin gain on Booking</th>
                    <th>Coin gain on Review</th> */}
                    <th>Start Hour</th>
                    <th>End Hour</th>
                    <th>Is Seat</th>
                    <th>Vendor Name</th>
                    <th>Photos</th>
                    <th>Added</th>
                    <th>Updated</th>
                    {/* <th>Delete</th> */}
                    {/* <th>Edit</th> */}
                    <th>Details</th>
                    <th>Request To Add Form</th>
                    <th>Block/Unblock</th>
                  </tr>
                </thead>
                <tbody>
                  {categories
                    // .filter(pack => !pack?.isEvent)
                    .map((packageOfEVENT, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{packageOfEVENT._id}</td>
                        <td>{packageOfEVENT.name}</td>
                        <td>
                          {moment(packageOfEVENT.eventStartDate).format(
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td>
                          {moment(packageOfEVENT.eventEndDate).format(
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td>{packageOfEVENT?.address}</td>
                        <td>{booleanToWord(packageOfEVENT?.isFemale)}</td>
                        <td>{packageOfEVENT?.location?.coordinates[1]}</td>
                        <td>{packageOfEVENT?.location?.coordinates[0]}</td>
                        {/* <td>{packageOfEVENT?.hours}</td> */}
                        <td>{packageOfEVENT?.price}</td>
                        {/* <td>{packageOfEVENT?.coinsGain}</td>
                      <td>{packageOfEVENT?.reviewCoinsGain}</td> */}
                        <td>{packageOfEVENT?.startHour}</td>
                        <td>{packageOfEVENT?.endHour}</td>
                        <td>{booleanToWord(packageOfEVENT?.isSeat)}</td>
                        <td>{packageOfEVENT?.vendorName}</td>
                        <td>{packageOfEVENT?.photos?.length}</td>

                        <td>
                          {moment(packageOfEVENT.createdAt).format(
                            'DD/MM/YYYY',
                          )}
                        </td>
                        <td>
                          {moment(packageOfEVENT.udatedAt).format('DD/MM/YYYY')}
                        </td>

                        {/* <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => _deleteCategory(packageOfEVENT._id)}
                          >
                            Delete
                          </button>
                        </td> */}
                        {/* <td>
                          <Link
                            to={{
                              pathname: '/package/add/vendor',
                              packageOfEVENT,
                            }}
                            className="btn btn-primary"
                          >
                            Edit{' '}
                          </Link>
                        </td> */}
                        <td>
                          <Link
                            to={{
                              pathname: '/package/detail/vendor',
                              packageOfEVENT,
                            }}
                            params={{ packageOfEVENT }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </td>

                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => setPackageId(packageOfEVENT._id)}
                          >
                            Add
                          </button>
                        </td>

                        {blockLoading === packageOfEVENT._id ? (
                          <td>
                            <button className="btn btn-primary">
                              Loading...
                            </button>
                          </td>
                        ) : (
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                setBlockUnBlockPackage(
                                  packageOfEVENT._id,
                                  packageOfEVENT.isBlock,
                                )
                              }
                            >
                              {packageOfEVENT.isBlock ? 'Unblock' : 'Block'}
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardHeader>Deals</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Card className="mb-3">
                <CardHeader>All Deals</CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Is Day</th>
                        <th>Is Month</th>
                        <th>Discount</th>
                        <th>Packages</th>
                        <th>Requested</th>
                        <th>Added</th>
                        <th>Updated</th>
                        <th>Request To Add Form</th>
                        {/* <th>Edit</th>
                    <th>Details</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {deals
                        .filter(deal => deal?.isMonth)
                        .map((deal, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{deal._id}</td>
                            <td>{deal.name}</td>
                            <td>{dateFormat(deal.startDate)}</td>
                            <td>{dateFormat(deal.endDate)}</td>
                            <td>{booleanToWord(deal?.isDay)}</td>
                            <td>{booleanToWord(deal?.isMonth)}</td>
                            <td>{deal?.discount}</td>

                            <td>{deal?.packages?.length}</td>
                            <td>{deal?.requested?.length}</td>

                            <td>
                              {moment(deal.createdAt).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              {moment(deal.udatedAt).format('DD/MM/YYYY')}
                            </td>

                            <td>
                              <button
                                className="btn btn-danger"
                                onClick={() => setDealId(deal._id)}
                              >
                                Add
                              </button>
                            </td>
                            {/* <td>
                          <Link
                            to={{
                              pathname: '/deal/add/',
                              deal,
                            }}
                            className="btn btn-primary"
                          >
                            Edit{' '}
                          </Link>
                        </td> */}
                            {/* <td>
                          <Link
                            to={{
                              pathname: '/deal/detail/',
                              deal,
                            }}
                            params={{ deal }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {packageId && (
        <Card className="mt-4">
          <CardHeader>Request to Deal</CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="packageId">Package Id</Label>
                  <Input
                    id="packageId"
                    // name="email"
                    // placeholder="with a placeholder"
                    type="text"
                    onChange={e => setPackageId(e.target.value)}
                    value={packageId}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="dealId">Deal Id</Label>
                  <Input
                    id="dealId"
                    // name="email"
                    // placeholder="with a placeholder"
                    type="text"
                    onChange={e => setDealId(e.target.value)}
                    value={dealId}
                  />
                </FormGroup>
              </Col>

              <Col>
                <Label
                  for="available"
                  style={{
                    opacity: 0,
                  }}
                >
                  available
                </Label>

                <Button
                  onClick={() => {
                    _requestToAdd();
                  }}
                >
                  Request to Add
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </Page>
  );
};

export default withRouter(VendorPackages);
