import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { CSVLink, CSVDownload } from 'react-csv';
import { dateFormat } from 'utils/globalFunctions';

const VendorBookings = () => {
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);

  const headers = [
    { label: 'Id', key: '_id' },
    {
      label: 'Package Name',
      key: 'package.name',
    },
    { label: 'Package Id', key: 'package.packageId' },
    { label: 'Event Start Date', key: 'package.eventStartDate' },
    { label: 'Event End Date', key: 'package.eventEndDate' },

    { label: 'Status', key: 'status' },
    { label: 'Address', key: 'package.address' },
    { label: 'hours', key: 'package.hours' },
    { label: 'Customer First Name', key: 'customer.firstName' },
    { label: 'Customer last Name', key: 'customer.lastName' },
    { label: 'Customer Email', key: 'customer.email' },
    { label: 'Customer mobile Number', key: 'customer.mobileNumber' },
    { label: 'Customer Uid', key: 'customer.uid' },
    { label: 'Number Of Guest', key: 'numberOfGuest' },
    { label: 'Amount', key: 'amount' },
    { label: 'Price', key: 'price' },
    { label: 'Discount', key: 'discount' },
    { label: 'onBookingCoinGain', key: 'onBookingCoinGain' },
    { label: 'dealId', key: 'dealId' },
    { label: 'Booking Starting Time', key: 'slots.startingTime' },
    { label: 'Booking Ending Time', key: 'slots.endTime' },
    { label: 'Booked On', key: 'createdAt' },
    { label: 'Updated On', key: 'updatedAt' },
    { label: 'Amount Paid', key: 'amountPaid' },
    { label: 'Customer Id', key: 'customerId' },
    { label: 'Vendor Id', key: 'vendorId' },
    { label: 'Payment Intent Id', key: 'paymentIntentID' },
    { label: 'Sub Category Id', key: 'subCategoryId' },
    { label: 'Payment Intent', key: 'paymentIntent' },
    { label: 'Selected Date', key: 'selectedDate' },
    { label: 'Vendor Name', key: 'vendorName' },
    { label: 'Is Female Check In Package', key: 'isFemaleCheckInPackage' },
  ];
  const _getSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/bookings/vendor');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        console.log('data.payload ===> ', data.payload);
        setCategories(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const toggle = () => setModal(!modal);

  const _cancelBooking = async id => {
    try {
      const { data } = await axios.get(
        `/admin/cancel/booking/${id}/vendor`,
        {},
      );
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _deleteCategory = async id => {
    try {
      const { data } = await axios.delete(`/admin/delete/subcategory/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getSubCategory();
  }, []);

  return (
    <Page
      title="Vendor Bookings Page"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="VendorBookings"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/subcategory/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto 0px',
      //     }}
      //   >
      //     Add SubCategory
      //   </Link>
      // )}
    >
      <CSVLink
        data={categories}
        filename={'my-VendorBookings.csv'}
        className="btn btn-primary mb-3"
        target="_blank"
        headers={headers}
      >
        Export Bookinks to Excel{' '}
      </CSVLink>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All Vendor Bookings</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Order Id</th>
                    <th>Package Name</th>
                    <th>Package Id</th>
                    <th>Event Start Date</th>
                    <th>Event End Date</th>
                    <th>Status</th>
                    <th>Address</th>
                    <th>hours</th>
                    <th>Customer First Name</th>
                    <th>Customer Last Name</th>
                    <th>Customer Email</th>
                    <th>Customer mobile Number</th>
                    <th>Customer Uid</th>
                    <th>Number Of Guest</th>
                    <th>Final Amount</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>On Booking Coin Gain</th>

                    <th>Deal Id</th>
                    <th>Slots</th>
                    <th>Selected Date</th>
                    <th>Selected Starting Time</th>
                    <th>Selected End Time</th>
                    <th>Booked On</th>
                    <th>Updated On</th>
                    <th>Cancel Booking</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.reverse().map((subcategory, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{subcategory.order_id}</td>
                      <td>{subcategory.package?.name}</td>
                      <td>{subcategory?.package?.packageId}</td>
                      <td>{subcategory?.package?.eventStartDate}</td>
                      <td>{subcategory?.package?.eventEndDate}</td>
                      <td>
                        {moment(new Date(subcategory?.selectedDate)).isBefore(
                          new Date(),
                          'day',
                        )
                          ? 'COMPLETED'
                          : subcategory?.status}
                      </td>
                      <td>{subcategory?.package?.address}</td>
                      <td>{subcategory?.package?.hours}</td>
                      <td>{subcategory?.customer?.firstName}</td>
                      <td>{subcategory?.customer?.lastName}</td>
                      <td>{subcategory?.customer?.email}</td>
                      <td>{subcategory?.customer?.mobileNumber}</td>
                      <td>{subcategory?.customer?.uid}</td>
                      <td>{subcategory?.numberOfGuest}</td>
                      <td>{subcategory?.amount}</td>
                      <td>{subcategory?.price}</td>
                      <td>{subcategory?.discount} %</td>
                      <td>{subcategory?.onBookingCoinGain}</td>
                      <td>{subcategory?.dealId}</td>

                      <td>{JSON.stringify(subcategory?.slots)}</td>
                      <td>{dateFormat(subcategory?.selectedDate)}</td>
                      <td>{subcategory?.slots?.startingTime}</td>
                      <td>{subcategory?.slots?.endTime}</td>
                      {/* <td>{subcategory?.selectedDate}</td> */}
                      <td>
                        {moment(subcategory.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {moment(subcategory.updatedAt).format('DD/MM/YYYY')}
                      </td>
                      {/* 
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => _deleteCategory(subcategory._id)}
                        >
                          Delete
                        </button>
                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: '/subcategory/add/',
                            subcategory,
                          }}
                          params={{ subcategory }}
                          className="btn btn-primary"
                        >
                          Edit{' '}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: '/subcategory/detail/',
                            subcategory,
                          }}
                          params={{ subcategory }}
                          className="btn btn-primary"
                        >
                          Detail{' '}
                        </Link>
                      </td> */}

                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => toggle()}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
          <ModalBody>Are you sure you want to delete?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                toggle();
                _cancelBooking(subcategory._id);
              }}
            >
              Yes
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Page>
  );
};

export default withRouter(VendorBookings);
