import React from 'react';

const error = () => {

    function mobileResponseFunction() {
        window.ReactNativeWebView.postMessage("false")
    }

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <div className="container" style={{ maxWidth: '380px', margin: '30px auto', overflow: 'hidden' }}>
                <div className="paper-container" style={{ position: 'relative', overflow: 'hidden', height: '467px' }}>
                    <div className="printer-bottom" style={{ background: '#ffffff', height: '447px', position: 'absolute', zIndex: 2, margin: '0 12px', marginTop: '-12px', animation: 'print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite', MozAnimation: 'print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9) infinite' }} />
                    <div className="paper">
                        <div className="main-contents" style={{ margin: '0 12px', padding: '24px' }}>
                            <div className="error-icon" style={{ textAlign: 'center', fontSize: '48px', height: '72px', background: '#f14014', borderRadius: '50%', width: '72px', margin: '16px auto', color: '#fff' }}>✖</div>
                            <div className="error-title" style={{ fontSize: '22px', textAlign: 'center', color: '#666', fontWeight: 'bold', marginBottom: '16px' }}>
                                Error in Payment
                                <br />
                                <br />
                                <br />
                                <button style={{ display: 'inline-block', padding: '15px 25px', fontSize: '24px', cursor: 'pointer', textAlign: 'center', color: '#fff', backgroundColor: '#49A9E3', border: 'none', borderRadius: '15px' }} onClick={mobileResponseFunction}>Proceed</button>
                            </div>
                            <div className="jagged-edge" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default error;