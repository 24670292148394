import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';
import Datetime from 'react-datetime';
import CustomDate from 'components/CustomDate';
import { toDate } from 'utils/globalFunctions';

const DealAdd = props => {
  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [discount, setDiscount] = useState(null);
  const [isDay, setIsDay] = useState(props?.location?.isDay);
  const [isMonth, setIsMonth] = useState(!props?.location?.isDay);
  const [isOnlyTime, setIsOnlyTime] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [id, setId] = useState(null);

  const [date, setDate] = useState(null);
  useEffect(() => {
    if (props?.location?.isDay) {
      // setEndDate(new Date())
      // setEndDate(new Date())
      setIsOnlyTime(true);
      setDate(moment(props?.location?.deal?.startDate).format('YYYY-MM-DD'));
      // setStartDate(moment(props?.location?.deal?.startDate).format('hh:mm:ss'));
      // setEndDate(moment(props?.location?.deal?.endDate).format('hh:mm:ss'));

      // console.log({
      //   DaATE: props?.location?.deal?.startDate,
      //   date,
      // });
    }
    if (props?.location?.deal) {
      setIsUpdate(true);
      setId(props?.location?.deal?._id);
      setName(props?.location?.deal?.name);
      setName(props?.location?.deal?.name);
      setStartDate(props?.location?.deal?.startDate);
      setEndDate(props?.location?.deal?.endDate);
      setDiscount(props?.location?.deal?.discount);
      setIsDay(props?.location?.deal?.isDay);
      setIsMonth(props?.location?.deal?.isMonth);
    }
  }, [props]);
  const _createPackage = async e => {
    e.preventDefault();
    try {
      if (isUpdate) {
        // console.log({
        //   name,
        //   startDate: isOnlyTime ? toDate(startDate) : startDate,
        //   endDate: isOnlyTime ? toDate(endDate) : endDate,
        //   discount,
        //   isDay,
        //   isMonth,
        // });
        const { data } = await axios.post(`/admin/update/deal/${id}`, {
          name,
          startDate: isOnlyTime ? toDate(startDate, date) : startDate,
          endDate: isOnlyTime ? toDate(endDate, date) : endDate,
          discount,
          isDay,
          isMonth,
        });
        console.log({ data });
        if (data.error) return toast.error(data.message);
        else {
          toast.success(data.message);
          // setVendors(data.payload);
        }
      } else {
        const { data } = await axios.post('/admin/create/deal', {
          name,
          startDate: isOnlyTime ? toDate(startDate, date) : startDate,
          endDate: isOnlyTime ? toDate(endDate, date) : endDate,
          discount,
          isDay,
          isMonth,
        });

        console.log({ data });
        if (data.error) return toast.error(data.message);
        else {
          toast.success(data.message);
          // setVendors(data.payload);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // const _getAllCategories = async () => {
  //   try {
  //     const { data } = await axios.get('/admin/getall/packageOfEVENT');
  //     console.log({ data });
  //     if (data.error) return toast.error(data.message);
  //     else {
  //       // toast.success(data.message);
  //       setAllCategories(data.payload);
  //     }
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };
  // useEffect(() => {
  //   _getAllCategories();
  // }, []);

  return (
    <Page
      title="Add Deal"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="DealAdd"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/vendor/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto',
      //     }}
      //   >
      //     Add Vendor
      //   </Link>
      // )}
    >
      <Row>
        <Col>
          <Card>
            <CardHeader>
              {isOnlyTime ? 'Day' : 'Month'} Deal Addition Form
            </CardHeader>
            <CardBody>
              <Form onSubmit={_createPackage}>
                <FormGroup row>
                  <Label for="name" sm={3}>
                    Name
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={e => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </FormGroup>
                {isOnlyTime ? (
                  <FormGroup row>
                    <Label for="date" sm={3}>
                      Date To show Deal
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        placeholder="date"
                        value={date}
                        onChange={e => {
                          setDate(e.target.value);
                        }}
                      />
                      {/* <Datetime /> */}
                    </Col>
                  </FormGroup>
                ) : null}
                <FormGroup row>
                  <Label for="hours" sm={3}>
                    Start {isOnlyTime ? 'Time' : 'Date'}
                  </Label>
                  <Col sm={10}>
                    {isOnlyTime ? (
                      <Input
                        type="time"
                        name="endHours"
                        placeholder="endHours"
                        onChange={e => {
                          // console.log({
                          //   DateTIme: toDate(e.target.value),
                          // });

                          setStartDate(e.target.value);
                        }}
                        value={startDate}
                      />
                    ) : (
                      <Datetime
                        value={new Date(startDate)}
                        onChange={e => {
                          setStartDate(e.format());
                        }}
                      />
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="hours" sm={3}>
                    End {isOnlyTime ? 'Time' : 'Date'}{' '}
                  </Label>
                  <Col sm={10}>
                    {isOnlyTime ? (
                      <Input
                        type="time"
                        name="endHours"
                        placeholder="endHours"
                        onChange={e => setEndDate(e.target.value)}
                        value={endDate}
                      />
                    ) : (
                      <Datetime
                        value={new Date(endDate)}
                        onChange={e => {
                          setEndDate(e.format());
                        }}
                      />
                    )}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="Discount" sm={3}>
                    Discount
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="Discount"
                      placeholder="Discount"
                      onChange={e => setDiscount(e.target.value)}
                      value={discount}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup row>
                  <Label for="price" sm={3}>
                    Price
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="price"
                      placeholder="Price"
                      onChange={e => setPrice(e.target.value)}
                      value={price}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="EventData" sm={3}>
                    Event Date
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="date"
                      name="EventData"
                      placeholder="Event Date"
                      onChange={e => setEventDate(e.target.value)}
                      value={eventDate}
                    />
                  </Col>
                </FormGroup>

               
                <FormGroup row>
                  <Label for="lat" sm={3}>
                    Latitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="lat"
                      placeholder="Latitude"
                      onChange={e => setLat(e.target.value)}
                      value={lat}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="long" sm={3}>
                    Longitude
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="long"
                      placeholder="Longitude"
                      onChange={e => setLong(e.target.value)}
                      value={long}
                    />
                  </Col>
                </FormGroup> */}
                {/* <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="checkbox2"
                      checked={isDay}
                      onChange={e => setIsDay(!isDay)}
                    />{' '}
                    Is Day
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="months"
                      checked={isMonth}
                      onChange={e => setIsMonth(!isMonth)}
                    />{' '}
                    Is Month
                  </Label>
                </FormGroup> */}

                {/* <FormGroup row>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    name="text"
                    placeholder="Description"
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                  />
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleFile" sm={3}>
                    Photo
                  </Label>
                  <Col sm={10}>
                    <UploadAndDisplayImage
                      url={photoUrl}
                      setUrl={setPhotoUrl}
                    />
                    <FormText color="muted">Select Image file only</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleFile" sm={3}>
                    Video
                  </Label>
                  <Col sm={10}>
                    <UploadAndDisplayImage
                      url={videoUrl}
                      setUrl={setVideoUrl}
                      isVideo={true}
                    />
                    <FormText color="muted">Select Video file only</FormText>
                  </Col>
                </FormGroup> */}

                {/* <FormGroup row>
                  <Label for="exampleSelect" sm={3}>
                    Select SubCategory
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="select"
                      name="select"
                      onChange={e => setSubCategoryId(e.target.value)}
                    >
                      <option value={null}>--please select--</option>
                      {subCategory.map(cat => (
                        <option
                          value={cat._id}
                          key={cat._id}
                          selected={cat?._id === subCategoryId}
                        >
                          {cat.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup> */}

                <FormGroup check row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button>{isUpdate ? 'Update' : 'Submit'}</Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(DealAdd);
