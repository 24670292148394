import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat } from 'utils/globalFunctions';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';

const CategoryDetail = props => {
  const [category, setCategory] = useState(null);
  const [showAddMorePhoto, setShowAddMorePhoto] = useState(false);
  const [newImage, setnewImage] = useState(null);
  const [subcategory, setSubCategory] = useState([]);

  const _getAllSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/subcategory');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setSubCategory(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    console.log({
      props,
    });
    if (props?.location?.category) {
      setCategory(props?.location?.category);
    }

    _getAllSubCategory();
  }, [props]);

  // const _addToPackage = async () => {
  //   const { data } = await axios.post(
  //     `/admin/add/photos/vendors/${category?._id}`,
  //     {
  //       photoUrl: newImage,
  //     },
  //   );

  //   toast.success(data.message);

  //   setnewImage('');
  // };
  const _addPackageToVendor = async id => {
    const { data } = await axios.post(
      `/admin/add/subcategory/to/category/${category?._id}`,
      {
        subCategoryId: id,
      },
    );
    toast.success(data.message);
  };
  const _removePackageToVendor = async id => {
    const { data } = await axios.post(
      `/admin/remove/subcategory/to/category/${category?._id}`,
      {
        subCategoryId: id,
      },
    );
    toast.success(data.message);
  };

  return (
    <Page
      title="Vendor Detail Page"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="CategoryDetail"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/category/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto 0px',
      //     }}
      //   >
      //     Add VendorDetails
      //   </Link>
      // )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>Details</CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <h6>Id</h6>
                  <p className="text-muted">{category?._id}</p>
                </Col>
                <Col>
                  <h6>Name</h6>
                  <p className="text-muted">{category?.name}</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h6>Image</h6>
                  <img
                    src={category?.photoUrl}
                    class="img-thumbnail"
                    alt="img-thumbnail"
                  />{' '}
                </Col>
                <Col>
                  <h6>Video</h6>
                  <video
                    controls
                    muted
                    src={category?.videoUrl}
                    class="img-thumbnail"
                    alt="img-thumbnail"
                    style={{}}
                  />{' '}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>
              Photos
              <Button onClick={() => setShowAddMorePhoto(true)}>
                Add More
              </Button>
            </CardHeader>

            <CardBody>
              {showAddMorePhoto && (
                <div className="border-1 border-primary">
                  <UploadAndDisplayImage url={newImage} setUrl={setnewImage} />
                  <Button onClick={_addToPackage}>Add To Package</Button>
                </div>
              )}
              <div className="img-grid">
                {category?.photos?.map(photo => (
                  <img src={photo?.imageUrl} alt="" className="img-fluid" />
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Card className="mt-4">
            <CardHeader>Sub Categories</CardHeader>

            <CardBody>
              <div className="img-grid">
                {category?.subCategories?.map(singleCat => {
                  const fullPackage = subcategory.find(
                    pac => pac?._id === singleCat?.categoryId,
                  );

                  return (
                    <div className="card card-body">
                      <Row>
                        <Col>
                          <h6>Id</h6>
                          <p className="text-muted">{fullPackage?._id}</p>
                        </Col>
                        <Col>
                          <h6>Name</h6>
                          <p className="text-muted">{fullPackage?.name}</p>
                        </Col>
                        <Col>
                          <h6>description</h6>
                          <p className="text-muted">
                            {fullPackage?.description}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>address</h6>
                          <p className="text-muted">{fullPackage?.address}</p>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname: '/subcategory/detail/',
                              subcategory: fullPackage,
                            }}
                            params={{ subcategory: fullPackage }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </Col>
                        <Col>
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              _removePackageToVendor(fullPackage._id)
                            }
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All subcategory</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>name</th>
                    <th>Packages</th>
                    <th>Photos</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Add</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {subcategory.map((subcategory, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{subcategory._id}</td>
                      <td>{subcategory.name}</td>
                      <td>{subcategory?.packages?.length}</td>
                      <td>{subcategory?.photos?.length}</td>

                      <td>
                        {moment(subcategory.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {moment(subcategory.udatedAt).format('DD/MM/YYYY')}
                      </td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => _addPackageToVendor(subcategory._id)}
                        >
                          Add
                        </button>
                      </td>
                      <td>
                        <Link
                          to={{
                            pathname: '/subcategory/detail/',
                            subcategory,
                          }}
                          params={{ subcategory }}
                          className="btn btn-primary"
                        >
                          Detail{' '}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Card className="mb-3 mt-3">
            <CardHeader>Add Packages to Vendor</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Address</th>
                    <th>isFemale</th>
                   
                    <th>Hours</th>
                    <th>Price</th>
                    <th>Is Seat</th>
                    <th>Photos</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {subcategory.map((packageOfEVENT, index) => (
                    <tr>
                      <th scope="row">{index + 1}</th>
                      <td>{packageOfEVENT._id}</td>
                      <td>{packageOfEVENT.name}</td>
                      <td>
                        {moment(packageOfEVENT.eventDate).format('DD/MM/YYYY')}
                      </td>
                      <td>{packageOfEVENT?.address}</td>
                      <td>{packageOfEVENT?.isFemale}</td>
                     
                      <td>{packageOfEVENT?.hours}</td>
                      <td>{packageOfEVENT?.price}</td>
                      <td>{packageOfEVENT?.isSeat}</td>
                      <td>{packageOfEVENT?.photos?.length}</td>

                      <td>
                        {moment(packageOfEVENT.createdAt).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {moment(packageOfEVENT.udatedAt).format('DD/MM/YYYY')}
                      </td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            _addPackageToVendor(packageOfEVENT._id)
                          }
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </Page>
  );
};

export default withRouter(CategoryDetail);
