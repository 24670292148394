import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import { booleanToWord, dateFormat, timeFormat } from 'utils/globalFunctions';

const DayDeal = () => {
  const [deals, setdeals] = useState([]);
  const _getSubCategory = async () => {
    try {
      const { data } = await axios.get('/admin/getall/deal');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setdeals(data.payload);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const _deleteCategory = async id => {
    try {
      const { data } = await axios.delete(`/admin/delete/deal/${id}`);
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        _getSubCategory();
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getSubCategory();
  }, []);

  return (
    <Page
      title="All Day Deals"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="Package"
      isLinkButton={true}
      linkButton={() => (
        <Link
          to={{
            pathname: '/deal/add/',
            isDay: true,
          }}
          className="btn btn-outline-primary"
          style={{
            height: 'fit-content',
            margin: 'auto 0px',
          }}
        >
          Add New Deal
        </Link>
      )}
    >
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader>All Day Deals</CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    {/* <th>Is Day</th>
                    <th>Is Month</th> */}
                    <th>Discount</th>
                    <th>Packages</th>
                    <th>Requested</th>
                    <th>Added</th>
                    <th>Updated</th>
                    <th>Delete</th>
                    <th>Edit</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {deals
                    .filter(deal => deal?.isDay)
                    .map((deal, index) => (
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{deal._id}</td>
                        <td>{deal.name}</td>
                        <td>{dateFormat(deal.startDate)}</td>
                        <td>{timeFormat(deal.startDate)}</td>
                        <td>{timeFormat(deal.endDate)}</td>
                        {/* <td>{booleanToWord(deal?.isDay)}</td> */}
                        {/* <td>{booleanToWord(deal?.isMonth)}</td> */}
                        <td>{deal?.discount}</td>

                        <td>{deal?.packages?.length}</td>
                        <td>{deal?.requested?.length}</td>

                        <td>{moment(deal.createdAt).format('DD/MM/YYYY')}</td>
                        <td>{moment(deal.udatedAt).format('DD/MM/YYYY')}</td>

                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => _deleteCategory(deal._id)}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: '/deal/add/',
                              deal,
                              isDay: true,
                            }}
                            className="btn btn-primary"
                          >
                            Edit{' '}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: '/deal/detail/',
                              deal,
                            }}
                            params={{ deal }}
                            className="btn btn-primary"
                          >
                            Detail{' '}
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(DayDeal);
