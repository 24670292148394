// import axios from 'axios';
// import moment from 'moment';
// import Page from 'components/Page';
// import React, { useEffect, useState } from 'react';
// import {
//   Button,
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Form,
//   FormFeedback,
//   FormGroup,
//   FormText,
//   Input,
//   Label,
//   Row,
// } from 'reactstrap';
// import toast, { Toaster } from 'react-hot-toast';
// import { withRouter, Link } from 'react-router-dom';
// import UploadAndDisplayImage from 'components/UploadAndDisplayImage';

// const PackageAddVendor = props => {
//   const [name, setName] = useState(null);

//   const [photoUrl, setPhotoUrl] = useState(null);
//   const [videoUrl, setVideoUrl] = useState(null);
//   const [description, setDescription] = useState(null);
//   const [address, setAddress] = useState(null);
//   const [lat, setLat] = useState(null);
//   const [long, setLong] = useState(null);
//   // const [hours, setHours] = useState(null);
//   const [price, setPrice] = useState(null);
//   const [isSeat, setIsSeat] = useState(false);
//   const [isLuxuryPicks, setIsLuxuryPicks] = useState(false);
//   const [isTopPicks, setIsTopPicks] = useState(false);
//   const [isEvent, setIsEvent] = useState(false);
//   const [subCategoryId, setSubCategoryId] = useState(null);
//   const [eventDate, setEventDate] = useState(null);
//   const [isFemale, setIsFemale] = useState(false);
//   const [vendorId, setVendorId] = useState(null);
//   const [tax, setTax] = useState(null);
//   // const [total, setTotal] = useState(null);
//   const [eventEndDate, setEventEndDate] = useState(null);

//   const [subCategory, setSubCategory] = useState([]);
//   // const [vendors, setVendors] = useState([]);
//   const [originalPrice, setOriginalPrice] = useState(null);
//   // const [discount, setDiscount] = useState(null);
//   // const [coinsGain, setCoinsGain] = useState(null);
//   // const [reviewCoinsGain, setReviewCoinsGain] = useState(null);
//   const [startHour, setStartHour] = useState(null);
//   const [endHour, setEndHour] = useState(null);

//   const [isUpdate, setIsUpdate] = useState(false);
//   const [id, setId] = useState(null);
//   useEffect(() => {
//     if (props?.location?.packageOfEVENT) {
//       setIsUpdate(true);
//       setId(props?.location?.packageOfEVENT?._id);
//       setName(props?.location?.packageOfEVENT?.name);
//       setPhotoUrl(props?.location?.packageOfEVENT?.photoUrl);
//       setVideoUrl(props?.location?.packageOfEVENT?.videoUrl);
//       setName(props?.location?.packageOfEVENT?.name);
//       setAddress(props?.location?.packageOfEVENT?.address);
//       setDescription(props?.location?.packageOfEVENT?.description);
//       setTax(props?.location?.packageOfEVENT?.tax);
//       // setTotal(props?.location?.packageOfEVENT?.total);

//       setOriginalPrice(props?.location?.packageOfEVENT?.originalPrice);
//       // setDiscount(props?.location?.packageOfEVENT?.discount);
//       // setCoinsGain(props?.location?.packageOfEVENT?.coinsGain);
//       // setReviewCoinsGain(props?.location?.packageOfEVENT?.reviewCoinsGain);
//       setStartHour(props?.location?.packageOfEVENT?.startHour);
//       setEndHour(props?.location?.packageOfEVENT?.endHour);
//       // setHours(props?.location?.packageOfEVENT?.hours);
//       // setDate((props?.location?.deal?.startDate).format('YYYY-MM-DD'));

//       setEventDate(
//         moment(props?.location?.packageOfEVENT?.eventStartDate).format(
//           'YYYY-MM-DD',
//         ),
//       );
//       setEventEndDate(
//         moment(props?.location?.packageOfEVENT?.eventEndDate).format(
//           'YYYY-MM-DD',
//         ),
//       );
//       setPrice(props?.location?.packageOfEVENT?.price);
//       setIsSeat(props?.location?.packageOfEVENT?.isSeat);
//       setIsFemale(props?.location?.packageOfEVENT?.isFemale);
//       setIsLuxuryPicks(props?.location?.packageOfEVENT?.isLuxuryPicks);
//       setIsEvent(props?.location?.packageOfEVENT?.isEvent);
//       setIsTopPicks(props?.location?.packageOfEVENT?.isTopPicks);
//       setSubCategoryId(props?.location?.packageOfEVENT?.subCategoryId);
//       setVendorId(props?.location?.packageOfEVENT?.vendorId);

//       setLat(props?.location?.packageOfEVENT?.location?.coordinates[1]);
//       setLong(props?.location?.packageOfEVENT?.location?.coordinates[0]);
//     }
//   }, [props]);
//   const _createPackage = async e => {
//     e.preventDefault();
//     try {
//       if (isUpdate) {
//         const { data } = await axios.post(
//           `/admin/update/package/${id}/vendor`,
//           {
//             name,
//             photoUrl,
//             videoUrl,
//             description,
//             address,
//             lat,
//             long,
//             // hours,
//             eventStartDate: eventDate,
//             eventEndDate,
//             price,
//             isFemale,
//             isSeat,
//             isTopPicks,
//             isLuxuryPicks,
//             subCategoryId,
//             vendorId,
//             // tax,
//             // total,
//             // originalPrice,
//             // discount,
//             // coinsGain,
//             startHour,
//             endHour,
//             isEvent,
//             // reviewCoinsGain,
//           },
//         );
//         console.log({ data });
//         if (data.error) return toast.error(data.message);
//         else {
//           toast.success(data.message);
//           // setVendors(data.payload);
//         }
//       } else {
//         const { data } = await axios.post('/admin/create/package/vendor', {
//           name,
//           photoUrl,
//           videoUrl,
//           description,
//           address,
//           lat,
//           long,
//           // hours,
//           eventStartDate: eventDate,
//           eventEndDate,
//           price,
//           isFemale,
//           isSeat,
//           isTopPicks,
//           isLuxuryPicks,
//           subCategoryId,
//           vendorId,
//           // tax,
//           // total,
//           // originalPrice,
//           // discount,
//           // coinsGain,
//           startHour,
//           endHour,
//           isEvent,
//           // reviewCoinsGain,
//         });

//         console.log({ data });
//         if (data.error) return toast.error(data.message);
//         else {
//           toast.success(data.message);
//           // setVendors(data.payload);
//         }
//       }
//     } catch (error) {
//       console.log({ error });
//     }
//   };
//   // useEffect(() => {
//   //   setTotal(parseInt(tax) + parseInt(price));
//   // }, [tax, price]);

//   const _getAllSubCategory = async () => {
//     try {
//       const { data } = await axios.get('/admin/getall/subcategory');
//       console.log({ data });
//       if (data.error) return toast.error(data.message);
//       else {
//         // toast.success(data.message);
//         setSubCategory(data.payload);
//       }
//     } catch (error) {
//       console.log({ error });
//     }
//   };
//   useEffect(() => {
//     _getAllSubCategory();
//   }, []);
//   // const _getAllVendors = async () => {
//   //   try {
//   //     const { data } = await axios.get('/admin/getall/vendor');
//   //     console.log({ data });
//   //     if (data.error) return toast.error(data.message);
//   //     else {
//   //       // toast.success(data.message);
//   //       setVendors(data.payload);
//   //     }
//   //   } catch (error) {
//   //     console.log({ error });
//   //   }
//   // };
//   // useEffect(() => {
//   //   _getAllVendors();
//   // }, []);

//   // const _getAllCategories = async () => {
//   //   try {
//   //     const { data } = await axios.get('/admin/getall/packageOfEVENT');
//   //     console.log({ data });
//   //     if (data.error) return toast.error(data.message);
//   //     else {
//   //       // toast.success(data.message);
//   //       setAllCategories(data.payload);
//   //     }
//   //   } catch (error) {
//   //     console.log({ error });
//   //   }
//   // };
//   // useEffect(() => {
//   //   _getAllCategories();
//   // }, []);

//   return (
//     <Page
//       title="Add Package"
//       breadcrumbs={[{ name: 'tables', active: true }]}
//       className="PackageAddVendor"
//       // isLinkButton={true}
//       // linkButton={() => (
//       //   <Link
//       //     to="/vendor/add"
//       //     className="btn btn-outline-primary"
//       //     style={{
//       //       height: 'fit-content',
//       //       margin: 'auto',
//       //     }}
//       //   >
//       //     Add Vendor
//       //   </Link>
//       // )}
//     >
//       <Row>
//         <Col>
//           <Card>
//             <CardHeader>Package Addition Form</CardHeader>
//             <CardBody>
//               <Form onSubmit={_createPackage}>
//                 <FormGroup row>
//                   <Label for="name" sm={3}>
//                     Name
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="text"
//                       name="name"
//                       placeholder="Name"
//                       onChange={e => setName(e.target.value)}
//                       value={name}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="startHours" sm={3}>
//                     Start Hours
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="time"
//                       name="startHours"
//                       placeholder="startHours"
//                       onChange={e => setStartHour(e.target.value)}
//                       value={startHour}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="endHours" sm={3}>
//                     End Hours
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="time"
//                       name="endHours"
//                       placeholder="endHours"
//                       onChange={e => setEndHour(e.target.value)}
//                       value={endHour}
//                     />
//                   </Col>
//                 </FormGroup>
//                 {/* <FormGroup row>
//                   <Label for="coinGain" sm={3}>
//                     Coin user will earn on Booking{' '}
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="coinGain"
//                       placeholder="coinGain"
//                       onChange={e => setCoinsGain(e.target.value)}
//                       value={coinsGain}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 {/* <FormGroup row>
//                   <Label for="reviewcoinGain" sm={3}>
//                     Coin user will earn on Review{' '}
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="reviewcoinGain"
//                       placeholder="reviewcoinGain"
//                       onChange={e => setReviewCoinsGain(e.target.value)}
//                       value={reviewCoinsGain}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 {/* <FormGroup row>
//                   <Label for="hours" sm={3}>
//                     Hours
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="text"
//                       name="hours"
//                       placeholder="Hours"
//                       onChange={e => setHours(e.target.value)}
//                       value={hours}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 {/* <FormGroup row>
//                   <Label for="originalPrice" sm={3}>
//                     Original Price
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="originalPrice"
//                       placeholder="originalPrice"
//                       onChange={e => setOriginalPrice(e.target.value)}
//                       value={originalPrice}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 {/* <FormGroup row>
//                   <Label for="discount" sm={3}>
//                     Discount
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="discount"
//                       placeholder="discount"
//                       onChange={e => setDiscount(e.target.value)}
//                       value={discount}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 <FormGroup row>
//                   <Label for="price" sm={3}>
//                     Lowest Price of Slots
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="price"
//                       placeholder="Price"
//                       onChange={e => setPrice(e.target.value)}
//                       value={price}
//                     />
//                   </Col>
//                 </FormGroup>
//                 {/* <FormGroup row>
//                   <Label for="tax" sm={3}>
//                     Tax
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="tax"
//                       placeholder="tax"
//                       onChange={e => setTax(e.target.value)}
//                       value={tax}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 {/* <FormGroup row>
//                   <Label for="total" sm={3}>
//                     Total
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="number"
//                       name="total"
//                       placeholder="total"
//                       onChange={e => setTotal(e.target.value)}
//                       value={total}
//                     />
//                   </Col>
//                 </FormGroup> */}
//                 <FormGroup row>
//                   <Label for="EventData" sm={3}>
//                     Event Date
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="date"
//                       name="EventData"
//                       placeholder="Event Date"
//                       onChange={e => setEventDate(e.target.value)}
//                       value={eventDate}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="eventEndDate" sm={3}>
//                     Event End Date
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="date"
//                       name="eventEndDate"
//                       placeholder="Event End Date"
//                       onChange={e => setEventEndDate(e.target.value)}
//                       value={eventEndDate}
//                     />
//                   </Col>
//                 </FormGroup>

//                 <FormGroup row>
//                   <Label for="address" sm={3}>
//                     Address
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="text"
//                       name="address"
//                       placeholder="Address"
//                       onChange={e => setAddress(e.target.value)}
//                       value={address}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="lat" sm={3}>
//                     Latitude
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="text"
//                       name="lat"
//                       placeholder="Latitude"
//                       onChange={e => setLat(e.target.value)}
//                       value={lat}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="long" sm={3}>
//                     Longitude
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="text"
//                       name="long"
//                       placeholder="Longitude"
//                       onChange={e => setLong(e.target.value)}
//                       value={long}
//                     />
//                   </Col>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="checkbox2"
//                       checked={isFemale}
//                       onChange={e => setIsFemale(!isFemale)}
//                     />{' '}
//                     Is Female
//                   </Label>
//                 </FormGroup>

//                 {/* <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="toppicks"
//                       checked={isTopPicks}
//                       onChange={e => setIsTopPicks(!isTopPicks)}
//                     />{' '}
//                     Is Top Picks
//                   </Label>
//                 </FormGroup> */}

//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="Luxury"
//                       checked={isLuxuryPicks}
//                       onChange={e => setIsLuxuryPicks(!isLuxuryPicks)}
//                     />{' '}
//                     Is Luxury Picks
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="event"
//                       checked={isEvent}
//                       onChange={e => setIsEvent(!isEvent)}
//                     />{' '}
//                     Is Event
//                   </Label>
//                 </FormGroup>
//                 {/* <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="topticks"
//                       checked={isTopPicks}
//                       onChange={e => setIsTopPicks(!isTopPicks)}
//                     />{' '}
//                     Is Top Picks
//                   </Label>
//                 </FormGroup> */}
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       id="isSeat"
//                       checked={isSeat}
//                       onChange={e => setIsSeat(!isSeat)}
//                     />{' '}
//                     Is seat
//                   </Label>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="description">Description</Label>
//                   <Input
//                     type="textarea"
//                     name="text"
//                     placeholder="Description"
//                     onChange={e => setDescription(e.target.value)}
//                     value={description}
//                   />
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="exampleFile" sm={3}>
//                     Photo
//                   </Label>
//                   <Col sm={10}>
//                     <UploadAndDisplayImage
//                       url={photoUrl}
//                       setUrl={setPhotoUrl}
//                     />
//                     <FormText color="muted">Select Image file only</FormText>
//                   </Col>
//                 </FormGroup>
//                 <FormGroup row>
//                   <Label for="exampleFile" sm={3}>
//                     Video
//                   </Label>
//                   <Col sm={10}>
//                     <UploadAndDisplayImage
//                       url={videoUrl}
//                       setUrl={setVideoUrl}
//                       isVideo={true}
//                     />
//                     <FormText color="muted">Select Video file only</FormText>
//                   </Col>
//                 </FormGroup>

//                 <FormGroup row>
//                   <Label for="exampleSelect" sm={3}>
//                     Select SubCategory
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="select"
//                       name="select"
//                       onChange={e => setSubCategoryId(e.target.value)}
//                     >
//                       <option value={null}>--please select--</option>
//                       {subCategory.map(cat => (
//                         <option
//                           value={cat._id}
//                           key={cat._id}
//                           selected={cat?._id === subCategoryId}
//                         >
//                           {cat.name}
//                         </option>
//                       ))}
//                     </Input>
//                   </Col>
//                 </FormGroup>
//                 {/* <FormGroup row>
//                   <Label for="exampleSelect" sm={3}>
//                     Select Vendors
//                   </Label>
//                   <Col sm={10}>
//                     <Input
//                       type="select"
//                       name="select"
//                       onChange={e => setVendorId(e.target.value)}
//                     >
//                       <option value={null}>--please select--</option>
//                       {vendors.map(ven => (
//                         <option
//                           value={ven._id}
//                           key={ven._id}
//                           selected={ven?._id === vendorId}
//                         >
//                           {ven.name}
//                         </option>
//                       ))}
//                     </Input>
//                   </Col>
//                 </FormGroup> */}

//                 <FormGroup check row>
//                   <Col sm={{ size: 10, offset: 2 }}>
//                     <Button>{isUpdate ? 'Update' : 'Submit'}</Button>
//                   </Col>
//                 </FormGroup>
//               </Form>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </Page>
//   );
// };

// export default withRouter(PackageAddVendor);

import React from 'react';

const PackageAddVendor = () => {
  return <div>PackageAddVendor</div>;
};

export default PackageAddVendor;
