import axios from 'axios';
import moment from 'moment';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  CustomInput,
} from 'reactstrap';
import toast, { Toaster } from 'react-hot-toast';
import { withRouter, Link } from 'react-router-dom';
import UploadAndDisplayImage from 'components/UploadAndDisplayImage';

const Constants = props => {
  const [constants, setConstants] = useState(null);
  const [OneCoin, setOneCoin] = useState(null);
  const [bookingReviewCoinsGain, setBookingReviewCoinsGain] = useState(null);
  const [onBookingCoinGain, setOnBookingCoinGain] = useState(null);
  const [vendorReviewCoinsGain, setVendorReviewCoinsGain] = useState(null);
  const [tax, setTax] = useState(null);
  const [isByPercentage, setIsByPercentage] = useState(false);
  const [isByPercentageForDiscount, setIsByPercentageForDiscount] = useState(false);
  const [signUpBonusValue, setSignUpBonusValue] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [signUpEndDate, setSignUpEndDate] = useState(null);
  const [discEndDate, setDiscEndDate] = useState(null);
  const _getConstants = async () => {
    try {
      const { data } = await axios.get('/admin/get/constants');
      console.log({ data });
      if (data.error) return toast.error(data.message);
      else {
        // toast.success(data.message);
        setConstants(data.payload[0]);
        setBookingReviewCoinsGain(data.payload[0].bookingReviewCoinsGain);
        setOneCoin(data.payload[0].OneCoin);
        setOnBookingCoinGain(data.payload[0].onBookingCoinGain);
        setVendorReviewCoinsGain(data.payload[0].vendorReviewCoinsGain);
        setTax(data.payload[0].tax);
        setIsByPercentage(data.payload[0].signupBonus.isByPercentage);
        setSignUpBonusValue(data.payload[0].signupBonus.value);
        setIsByPercentageForDiscount(
          data.payload[0].autoDiscountForFivePurchases.isByPercentage,
        );
        setDiscountValue(data.payload[0].autoDiscountForFivePurchases.value);
        setSignUpEndDate(data.payload[0].signupBonus.expiryDays);
        setDiscEndDate(data.payload[0].autoDiscountForFivePurchases.expiryDays);

      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    _getConstants();
  }, []);

  const _update = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('/admin/update/constants', {
        OneCoin,
        bookingReviewCoinsGain,
        onBookingCoinGain,
        vendorReviewCoinsGain,
        tax,
        id: constants._id,
      });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const _updateSignUpBonus = async (e) => {
    e.preventDefault();
    try{
      const { data } = await axios.post('/admin/update/constants', {
        signupBonus: {
          'isByPercentage':isByPercentage,
          'value': signUpBonusValue,
          'expiryDays': signUpEndDate,
        },
        id: constants._id,
      });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
      }
    }catch(err) {
      console.log({err});
    }
  };

  const _updateDiscount = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('/admin/update/constants', {
        autoDiscountForFivePurchases: {
          isByPercentage: isByPercentageForDiscount,
          value: discountValue,
          expiryDays: discEndDate,
        },
        id: constants._id,
      });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Page
      title="Constants"
      breadcrumbs={[{ name: 'tables', active: true }]}
      className="Constants"
      // isLinkButton={true}
      // linkButton={() => (
      //   <Link
      //     to="/vendor/add"
      //     className="btn btn-outline-primary"
      //     style={{
      //       height: 'fit-content',
      //       margin: 'auto',
      //     }}
      //   >
      //     Add Vendor
      //   </Link>
      // )}
    >
      <Row>
        <Col xl={8} lg={12} md={12}>
          <Card>
            <CardHeader>Sub Category Addition Form</CardHeader>
            <CardBody>
              <Form onSubmit={_update}>
                <FormGroup row>
                  <Label for="OneCoin" sm={4}>
                    One Currency Is Equal To
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="OneCoin"
                      placeholder="OneCoin"
                      onChange={e => setOneCoin(e.target.value)}
                      value={OneCoin}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="vendorReviewCoinsGain" sm={4}>
                    Vendor Review Coin Gain{' '}
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="vendorReviewCoinsGain"
                      placeholder="vendorReviewCoinsGain"
                      onChange={e => setVendorReviewCoinsGain(e.target.value)}
                      value={vendorReviewCoinsGain}
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup row>
                  <Label for="bookingReviewCoinsGain" sm={4}>
                    booking Review Coins Gain
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="bookingReviewCoinsGain"
                      placeholder="bookingReviewCoinsGain"
                      onChange={e => setBookingReviewCoinsGain(e.target.value)}
                      value={bookingReviewCoinsGain}
                    />
                  </Col>
                </FormGroup> */}
                {/* <FormGroup row>
                  <Label for="onBookingCoinGain" sm={4}>
                    On Booking Coin Gain{' '}
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="onBookingCoinGain"
                      placeholder="onBookingCoinGain"
                      onChange={e => setOnBookingCoinGain(e.target.value)}
                      value={onBookingCoinGain}
                    />
                  </Col>
                </FormGroup> */}
                {/* <FormGroup row>
                  <Label for="lat" sm={4}>
                    Latitude
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="lat"
                      placeholder="Latitude"
                      onChange={e => setLat(e.target.value)}
                      value={lat}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="long" sm={4}>
                    Longitude
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      name="long"
                      placeholder="Longitude"
                      onChange={e => setLong(e.target.value)}
                      value={long}
                    />
                  </Col>
                </FormGroup> */}

                <FormGroup check row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button>Update</Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xl={8} lg={12} md={12}>
          <Card>
            <CardHeader>Sign Up Bonus</CardHeader>
            <CardBody>
              <Form onSubmit={_updateSignUpBonus}>
                <FormGroup row>
                  <Label for="value" sm={2}>
                    Sign Up Bonus Value
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="value"
                      placeholder="Value"
                      onChange={e => setSignUpBonusValue(e.target.value)}
                      value={signUpBonusValue}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Invite" sm={2} style={{ wordWrap: 'normal' }}>
                    {isByPercentage ? 'By Percentage' : 'By Value'}
                  </Label>
                  <Col
                    sm={10}
                    style={{ display: 'flex', alignItems: 'center' }}
                    gi
                  >
                    <CustomInput
                      type="switch"
                      id="isByPercentage"
                      checked={isByPercentage}
                      onChange={e => setIsByPercentage(!isByPercentage)}
                    />
                    <span>{isByPercentage ? 'On' : 'Off'}</span>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="endDate" sm={2}>
                    Expiry Days
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="number"
                      name="endDate"
                      placeholder="End Days"
                      onChange={e => setSignUpEndDate(e.target.value)}
                      value={signUpEndDate}
                    />
                  </Col>
                </FormGroup>
                <FormGroup check row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button>Update</Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <br />
      <Row>
        <Col xl={8} lg={12} md={12}>
          <Card>
            <CardHeader>Auto Discount For Five Purchase</CardHeader>
            <CardBody>
              <Form onSubmit={_updateDiscount}>
                <FormGroup row>
                  <Label for="value" sm={2}>
                    Discount Value:
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="value"
                      placeholder="Value"
                      onChange={e => setDiscountValue(e.target.value)}
                      value={discountValue}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="Invite" sm={2} style={{ wordWrap: 'normal' }}>
                    {isByPercentageForDiscount ? 'By Percentage' : 'By Value'}
                  </Label>
                  <Col
                    sm={10}
                    style={{ display: 'flex', alignItems: 'center' }}
                    gi
                  >
                    <CustomInput
                      type="switch"
                      id="invite"
                      checked={isByPercentageForDiscount}
                      onChange={e =>
                        setIsByPercentageForDiscount(!isByPercentageForDiscount)
                      }
                    />
                    <span>{isByPercentageForDiscount ? 'On' : 'Off'}</span>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="endDate" sm={2}>
                    Expiry Days
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="number"
                      name="endDate"
                      placeholder="End Days"
                      onChange={e => setDiscEndDate(e.target.value)}
                      value={discEndDate}
                    />
                  </Col>
                </FormGroup>
                <FormGroup check row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button>Update</Button>
                  </Col>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(Constants);
