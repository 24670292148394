import logo200Image from 'assets/img/logo/logo_200.png';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: null, password: null };
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      const { data } = await axios.post('/admin/signin', {
        email: this.state.email,
        password: this.state.password,
      });

      console.log({
        data,
      });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        this.props.history.push('/');
        localStorage.setItem('@auth_token', data.payload);
        localStorage.setItem('@is_Vendor', false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // toast.error('Email and password are not same');
    }
  };
  handleVendorSubmit = async event => {
    event.preventDefault();

    try {
      const { data } = await axios.post('/admin/signin/vendor', {
        email: this.state.email,
        password: this.state.password,
      });

      console.log({
        data,
      });
      if (data.error) return toast.error(data.message);
      else {
        toast.success(data.message);
        this.props.history.push('/');
        localStorage.setItem('@auth_token', data.payload);
        localStorage.setItem('@is_Vendor', true);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      // toast.error('Email and password are not same');
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return 'Vendor Login';
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input
            {...usernameInputProps}
            onChange={e =>
              this.setState({
                email: e.target.value,
              })
            }
            value={this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input
            {...passwordInputProps}
            onChange={e =>
              this.setState({
                password: e.target.value,
              })
            }
            value={this.state.password}
          />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )}
        {/* <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}
        >
          Admin Login
        </Button>
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleVendorSubmit}
        >
          Vendor Login
        </Button>

        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div> */}

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE__VENDOR_LOGIN = 'VENDOR_LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Emailssss',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default withRouter(AuthForm);
